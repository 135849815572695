import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';
import {  Subject } from 'rxjs';
import { UntypedFormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { CommonService } from '../../services/common/common.service';
import { DataTransferService } from '../../services/common/datatransfer.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { GlobalConstants } from '../../services/common/global-variables.service';
import { CardStatuses } from 'src/app/interfaces/auth.types';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @Output() loginStatus = new EventEmitter();
  private subject: Subject<string> = new Subject();
  modalref!: BsModalRef;
  hideEvent: EventEmitter<boolean> = new EventEmitter();
  userEnteredDetails = {
    num : '',
    code: '',
    alreadyChecked : false
  }

  login_steps = ['card','mobile','otp'];
  IDLE_TIMEOUT = 900;
  _idleSecondsTimer = null;
  _idleSecondsCounter = 0;
  showLoggedOut: boolean = false;
  stepToShow!: string;


  selectedCountry: any;

  showStepZero: boolean = false;
  notApplicableCard = false;

  ifExistingUser: boolean = true;
  showIfNotAuthenticated: boolean = false;
  invalidUser: boolean = false;
  userSearching: boolean = false;
  errorRequestingOtp: boolean = false;
  invalidOtp: boolean = false;
  validOtp: boolean = false;
  invalidCard: boolean = false;
  userId: any;
  urlcode: any;


  otpInp: any;
  mobileInputBtnActive: any = false;
  emailPattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  createWithUserForm: any = this.fb.group({
    mobile: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]]
  });
  get f() { return this.createWithUserForm.controls; }
  mobNum: any;
  validateUserOtpForm: any = this.fb.group({
    userId: [''],
    otp: [''],
  });
  addCardForm: any = this.fb.group({
    binFirst4: [''],
    binLast4: ['']
  });

  isload1: boolean = false;
  isload2: boolean = false;
  loginProgress: boolean = false;
  timer: any = 'initial';
  viewTimer: any = 0;
  timerToShow: any;
  userStatus: any = "login";
  userCards: any = [];
  binError: boolean = false;
  binInvalid: boolean = false;
  cardVerified: boolean = false;
  loginCardDetails: any;
  submittedForm1: any;
  errorRequestingOtpMsg: any;
  searchLoader: boolean = false;
  otpVerifying: boolean = false;
  showNoCards: boolean = false;
  showNoCardsMessage: any = "";
  fetchingCard: boolean = false;
  cardNum: any;
  featuredCountryCodes = ['IND', 'SGP', 'ARE', 'AUS', 'USA', 'GBR', 'CAN', 'SAU', 'KWT', 'QAT', 'BHR', 'OMN', 'HKG', 'NZL', 'BGD'];
  popularCountries = [];
  otherCountries = [];
  public CardStatuses = CardStatuses;

  currentStep = 0;
  invalidEmail = false;
  clearAll(){
    this.currentStep = 0;
    this.cardNum = null;
    this.fetchingCard = false;
    this.showNoCards = false;
    this.otpVerifying = false;
    this.searchLoader = false;
    this.submittedForm1 = false;
    this.binError = false;
    this.isload1 = false;
    this.isload2 = false;

    this.cardVerified = false;
    this.binInvalid = false;
    this.ifExistingUser = true;
    this.showNoCardsMessage = '';
    this.errorRequestingOtpMsg = '';
    this.createWithUserForm.patchValue({ mobile: '' });
    this.createWithUserForm.patchValue({ email: '' });
    this.mobNum = undefined;
    this.otpInp = undefined;
  }
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _authService: AuthService,
    private commonService: CommonService,
    private dataTransferService: DataTransferService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    public GlobalConstants: GlobalConstants
  ) {}

  ngOnInit() {
    this.currentStep = 0;
    this.stepToShow = this.login_steps[0];
    setTimeout(() => {    //<<<---    using ()=> syntax
      document.getElementById("first_four_card_digits")?.focus();
    }, 600);
    let card_verified = localStorage.getItem('card_verified');
    if (!card_verified || card_verified == "false") {
      this.cardVerified = false;
    }
    else {
      this.cardVerified = true;
    }
    this.getAllCountries();


    this.subject.pipe(
      debounceTime(500)
    ).subscribe(inp => {
      this.checkLength()
      this.invalidUser = false;
    });
  }
  debouncedUserSearch(inp: any) {
    // this.subject.next(inp);
    this.checkLength()
    this.invalidUser = false;
  }
  checkSelectedCountry(){
    let selectedCountry = JSON.parse(localStorage.getItem('userCountry'));
    if(selectedCountry){
      this.selectedCountry = selectedCountry.attributes;
      if(!this.selectedCountry.min_mobile_digit || !this.selectedCountry.max_mobile_digit){
        let mobile_length = this.selectedCountry.mobile_length.split('...');
        this.selectedCountry['min_mobile_digit'] = parseInt(mobile_length[0]);
        this.selectedCountry['max_mobile_digit'] = parseInt(mobile_length[1]);
      }
    }
  }

  reloadInit() {
    this.ngOnInit();
  }
  removeLetter(str) {
    this.createWithUserForm.controls['mobile'].setValue(str.replace(/\D/g, ""));
  }
  checkLength(){
    if (this.createWithUserForm?.value?.mobile?.length > (this.selectedCountry.min_mobile_digit - 1) && this.createWithUserForm?.value?.mobile?.length < (this.selectedCountry.max_mobile_digit + 1)) {
      this.mobileInputBtnActive = true;
    }
    else{
      this.mobileInputBtnActive = false;
    }
  }
  userSearch() {
    this.invalidEmail = false;
    if (this.createWithUserForm?.value?.mobile?.length > (this.selectedCountry.min_mobile_digit - 1) && this.createWithUserForm?.value?.mobile?.length < (this.selectedCountry.max_mobile_digit + 1)) {
      this.errorRequestingOtpMsg = "";
      this.userId = "";
      this.searchLoader = true;
      this.userSearching = true;
      if(this.userEnteredDetails.alreadyChecked && this.userEnteredDetails.num === this.createWithUserForm.value.mobile && this.userEnteredDetails.code == this.selectedCountry.calling_codes[0]) {
        this.createUser();
      }
      else {
        this._authService.validateExistingUser(this.createWithUserForm.value.mobile, this.selectedCountry.calling_codes[0],this.selectedCountry.id).subscribe((response) => {
          this.userSearching = false;
          this.searchLoader = false;
          if (response?.id) {
            this.ifExistingUser = true;
            this.userId = response.id;
            this.userStatus ="login";
            this.requestPoshvineOtp(true);
          } else {
            // show email
              this.mobileInputBtnActive = true;
              this.userSearching = false;
              this.searchLoader = false;
              this.ifExistingUser = false;
              this.userId = "";
              if(this.userEnteredDetails.alreadyChecked) {
                this.createUser()
              }
          }
          this.userEnteredDetails = {
            num: this.createWithUserForm.value.mobile,
            alreadyChecked: true,
            code: this.selectedCountry.calling_codes[0]
          }
        })
      }
    }
    else{
      this.searchLoader = false;
      this.userSearching = false;
    }
  }
  createUser() {
    this.submittedForm1 = true;
    if(this.createWithUserForm.valid || this.createWithUserForm.value.email == '') {
      this._authService.createUser(this.createWithUserForm.value.mobile, this.createWithUserForm.value.email, this.selectedCountry.calling_codes[0], this.selectedCountry.id).subscribe((response) => {
        this.isload1 = false;
        if (response && response.id) {
          this.currentStep = this.currentStep + 1;
          this.stepToShow = this.login_steps[this.currentStep];
          this.submittedForm1 = false;
          this.countDown(180, () => {
            this.timer = 'complete';
          });

          setTimeout(() => {    //<<<---    using ()=> syntax
            document.getElementById("otp-field")?.focus();
          }, 1000);
          this.userId = response.id;
          this.userStatus = "activation";
        } else {
          this.invalidUser = true;
          this.submittedForm1 = false;
        }
      }, error => {
        this.isload1 = false;
        if (error.error) {
          this.errorRequestingOtpMsg = error.error.errors.join(", ");
        } else {
          this.errorRequestingOtpMsg = "Please check your eligibility.";
        }

        this.errorRequestingOtp = true;
        this.submittedForm1 = false;

      })

    }
    else{
      this.searchLoader = false;
      this.userSearching = false;
      this.submittedForm1 = false;
      this.invalidEmail = true;
    }
  }
  closeAuthentication() {
    this.clearAuthStorage();
    this.showIfNotAuthenticated = false;
  }
  clearAuthStorage(){
    localStorage.removeItem('currentUser');
    localStorage.removeItem('card_verified');
    localStorage.removeItem('cardSelected');
    localStorage.removeItem('userCard');
    localStorage.removeItem('userCards');
  }


  requestPoshvineOtp(goTonextStep = true) {
    this.isload1 = true;
    this._authService.requestUserOtp(this.createWithUserForm.value.mobile, this.userId).subscribe((response) => {
      if (response) {
        this.countDown(180, () => {
          this.timer = 'complete';
        });
        setTimeout(() => {
          this.submittedForm1 = false;
          this.showStepZero = false;
          this.isload1 = false;
          if(goTonextStep){
            this.currentStep = this.currentStep + 1;
            this.stepToShow = this.login_steps[this.currentStep];
          }
        }, 1000);
        setTimeout(() => {    //<<<---    using ()=> syntax
          document.getElementById("otp-field").focus();
        }, 1800);
      } else {
        this.isload1 = false;
        this.isload2 = false;
        this.errorRequestingOtpMsg = "OTP Request limit exceeded. Please try after 30 mins.";
        this.errorRequestingOtp = true;
        this.submittedForm1 = false;
      }
    }, error => {
      this.isload1 = false;
      this.isload2 = false;
      this.submittedForm1 = false;
      this.mobileInputBtnActive = true;
      if(error.status == 422){
        this.errorRequestingOtpMsg = "OTP Request limit exceeded. Please try after 30 mins.";
        this.errorRequestingOtp = true;
      }
      else{
        this.toastr.error('Something went wrong. Please try again!');
      }
    })
  }


  validateUserOtp() {
    if (this.otpInp && this.otpInp.length == 6) {
      this.otpVerifying = true;
        this._authService.validateUserOtp(this.userId, this.otpInp, this.userStatus, this.mobNum).subscribe((response) => {
          if (response && response.token) {
              localStorage.setItem('card_verified', "true");
              this.getUserCard()
          } else {
            // if(response && response.wrong_attempts_left && response.wrong_attempts_left == 0){
            //   this.errorRequestingOtpMsg = "Maximum invalid OTP attempts reached";
            // }
            this.invalidOtp = true;
            this.validOtp = false;
            this.otpVerifying = false;
          }
        }, (err) => {
          console.log(err)
          this.otpVerifying = false;
          this.errorRequestingOtp = true;
          this.errorRequestingOtpMsg = "Please check the entered OTP";
        })
    }
  }

  getUserCard() {
    this.fetchingCard = true;
    this._authService.getCards().subscribe((response) => {
      this.fetchingCard = false;
      if (response && response.data && (response.data.length > 0 || response.data.attributes)) {
          var currentCard;
          if (response.data.attributes) {
            currentCard[0] = response.data;
          }
          else {
            currentCard = response.data.filter((x) => { return x.attributes.bin == this.bin });
          }
          var card;
          if (currentCard.length == 1) {
            localStorage.setItem('uc', JSON.stringify(currentCard[0].id));
            this.checkIfBinValid(currentCard[0].attributes.bin);
          }
          else {
            this.addCard("first8",true, true);
          }
      } else {
          this.addCard("first8",false, true);
      }
    });
  }

  addCard(bin_type, reload = false, binCheck = false) {
    this._authService.addCard(this.bin, bin_type).subscribe((response) => {
      if (response && response.data && response.data.attributes) {
        let card = JSON.stringify(response.data.id);
        localStorage.setItem('uc', card);
        localStorage.setItem('card_verified', "true");
        localStorage.removeItem('userCards');
        this.validOtp = true;

        if (binCheck) {
          this.checkIfBinValid(this.bin);
        }
        else if (reload) {
          // this.reloadInit();
          location.reload();
        }
      }
    });
  }



  checkIfBinValid(bin) {
    this._authService.validateCard(bin, 'first8').subscribe(res => {
      this.isload2 = false;

      if (res && res.data && res.data.attributes.bin == bin && res.data.attributes.status == CardStatuses.bin_verified) {
        let current_user = JSON.parse(localStorage.getItem("currentUser"));
        current_user.token = res.data.attributes.session_token;
        localStorage.setItem('currentUser', JSON.stringify(current_user));
        this.cardVerified = true;
        localStorage.setItem('card_verified', "true");
        let card = JSON.stringify({ card_id: res.data.attributes.id });
        localStorage.setItem('userCard', JSON.stringify(res.data));
        let usercurrentcard = JSON.stringify(res);
        this.closeModalRef();
        this.binError = false;
        this.binInvalid = false;
        this.otpVerifying = false;
        this.validOtp = true;
        // this.reloadInit();
        this.toastr.success('Logged In Successfully','', {
          timeOut: 6000,
        });
        this.addLocation();
        this.setCountry({
          attributes : this.selectedCountry
        })
        this.dataTransferService.nextRefreshEvent(true);

        setTimeout(() => {
          this.loginStatus.emit(true);
        }, 300);

      }
      else {
        this.binError = true;
        this.otpVerifying = false;
        this.toastr.error('Something went wrong. Please try again!');
        localStorage.setItem('card_verified', "false");
      }
    }, (err) => {

      console.log(err)
    })
  }

  addLocation(){
    let userLocation = JSON.parse(localStorage.getItem("userLocation"));
    if(userLocation && userLocation.country && userLocation.country.id){
      let params = {
        country_id: userLocation.country.id,
        // locality_id:
      }
      if(userLocation.state && userLocation.state.id){
        params['city_id'] = userLocation.state.id;
      }
      this._authService.addLocation(params).subscribe(res => {});
    }
  }
  bin! : string;
  addCardFormSubmit() {
    if (this.binError) {
      this._authService.logout();
      window.location.href = "/";
    }
    this.isload2 = true;
    this.bin = this.addCardForm.value.binFirst4.toString() + this.addCardForm.value.binLast4.toString();

    this.cardValidation(this.bin);
  }

  allCountries: any = [];
  filteredCountries = [];
  countrySearchTerm = '';
  getAllCountries(countryCode?) {
    let allCountries = JSON.parse(localStorage.getItem("allCountries"));
    if(!allCountries || allCountries.length == 0){
      this.commonService.getAllCountries().subscribe((res: any) => {
        this.allCountries = res;
        this.filteredCountries = res;
        this.checkSelectedCountry();
        if(countryCode){
          this.setCountryByCode(countryCode);
        }
      });
    }
    else{
      this.allCountries = allCountries;
      this.filteredCountries = allCountries;
      if(countryCode){
        this.setCountryByCode(countryCode);
      }
      this.checkSelectedCountry();
    }
  }
  filterItem(searchTerm){
   if(searchTerm && searchTerm.length > 0){
      this.filteredCountries = Object.assign([], this.allCountries).filter(
        item => item.attributes.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
     )
   }
  }
  setCountryByCode(countryCode){
    this.filteredCountries = this.allCountries;
    let country = this.allCountries.filter((x) => { return x.attributes.iso_code == countryCode })
    if(country && country.length > 0){
      this.selectedCountry = country[0].attributes;
      this.checkSelectedCountry();
    }
  }
  setCountry(country) {
    this.selectedCountry = country.attributes;
    localStorage.setItem("userCountry", JSON.stringify(country));
  }
  onKeyph(e) {
    this.binError = false;
    this.binInvalid = false;
    e.target.value = e.target.value.replace(/[^\d]/g, '').trim();
  }
  keytab(event, el) {
    this.binError = false;
    this.binInvalid = false;
    this.isload2 = false;
    if (event.currentTarget.value.length == 4) {
      // document.getElementById('last_four_card_digits').focus();
      el.focus();
    }
  }
  btnActive: boolean = false;

  resetLoginAgain() {
    this.clearAll();
    this.submittedForm1 = false;
    this.fetchingCard = false;
    this.searchLoader = false;
    this.isload1 = false;
    this.isload2 = false;
    this.stepToShow = this.login_steps[0];
    this.currentStep = 0;
    this.mobNum = undefined;
    this.mobileInputBtnActive = false;
    this.errorRequestingOtp = false;
    this.invalidUser = false;
    this.errorRequestingOtpMsg = "";
    this.invalidEmail = false;
    this.userEnteredDetails = {
      num : '',
      code: '',
      alreadyChecked : false
    }
    if (this.createWithUserForm.controls) {
      if (this.createWithUserForm.controls['mobile']) {
        this.createWithUserForm.controls['mobile'].setValue("");
      }
      if (this.createWithUserForm.controls['otp']) {
        this.createWithUserForm.controls['otp'].setValue("");
      }
    }
    this.otpInp = '';
    this.showIfNotAuthenticated = true;
    this.userSearching = false;
    this.validOtp = false;
    (clearInterval(this.int), function () { });
  }
  cardValidation(bin) {
    this._authService.cardValidation(bin).subscribe(res => {
      this.isload2 = false;
      if (res && res.success) {
        this._authService.binValidation(bin).subscribe(res => {
          this.loginCardDetails = res?.details;
        })
        this.cardVerified = true;
        this.getAllCountries(res.detail.country_code);
        localStorage.setItem('card_verified', "true");
        this.currentStep = this.currentStep + 1;
        if (this.login_steps[this.currentStep]) {
          this.stepToShow = this.login_steps[this.currentStep];
        }
        else {
          this.stepToShow = null;
        }
        this.binError = false;
        this.binInvalid = false;
      } else {
        this.binError = true;
        if(res && res.detail && res.detail.name){
          this.notApplicableCard = true;
        }
        else{
          this.notApplicableCard = true;
        }
        localStorage.setItem('card_verified', "false");
      }
    })
  }
  checkbtn() {
    let bin = this.addCardForm.value.binLast4.length
    if ( bin === 4) {
      this.btnActive = true;
    }
    else {
      this.btnActive = false;
    }
  }

  backkeytab(event, firstFocusable) {
    this.binError = false;
    this.binInvalid = false;
    this.isload2 = false;

    if (event.currentTarget.value.length == 0 && event.key == 'Backspace') {
      let elem = document.getElementById('first_four_card_digits')
      // elem.value = elem.value.slice(0, 3);
      elem.focus();
    }

  }

  int: any;
  countDown(i, callback) {

    callback = callback || function () { };
    this.int = setInterval(() => {
      this.timer = 'started';
      this.viewTimer = i;
      const minutes: number = Math.floor(i / 60);
      this.timerToShow = minutes + ':' + (i - minutes * 60);

      i-- || (clearInterval(this.int), callback());
    }, 1000);
  }

  clearOtp() {
    document.getElementById("otp-field").focus();
    this.otpInp = '';
  }
  clearCardInput(){
    this.binError = false;
    this.addCardForm.patchValue({ binFirst4: '' });
    this.addCardForm.patchValue({ binLast4: '' });
  }

  closeModalRef(){
    this.closeModal.emit(true);
  }
}
