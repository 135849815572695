import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpBackend } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { BehaviorSubject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LoginComponent } from 'src/app/core/components/login/login.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private login = new BehaviorSubject(true);
  login$ = this.login.asObservable();

  private changeCard = new BehaviorSubject(true);
  changeCard$ = this.changeCard.asObservable();
  apiUrlBsV1 = environment.apiUrlBsV1;
  modalRef!: BsModalRef;
  constructor(
    private http: HttpClient,
    private modalService: BsModalService,
  ) { }



  emitLogin(data: any) {
    this.login.next(data)
  }

  changeCardEmit(data: any) {
    this.changeCard.next(data)
  }
  openLoginModal() {
    const initialState = {}
    var config = {
      backdrop: true,
      ignoreBackdropClick: false,
      class: 'login-modal'
    };
    this.modalRef = this.modalService.show(LoginComponent, Object.assign({}, config, { initialState }));
    this.modalRef.content.closeModal.subscribe((res: any) => {
      this.modalRef.hide();
    })
  }
processCountries(res){
  if(res && res.data && res.data.length > 0){
    const originalCountries = res.data;
    

    localStorage.setItem("allCountries", JSON.stringify(originalCountries));
    let allCountries = originalCountries;

    allCountries.forEach(obj => {
      let mobile_length = obj.attributes.mobile_length.split('...');
      obj.attributes.min_mobile_digit = parseInt(mobile_length[0]);
      obj.attributes.max_mobile_digit = parseInt(mobile_length[1]);
      if (this.featuredCountryCodes.includes(obj.attributes.iso_code)) {
        this.popularCountries.push(obj);
      } else {
        this.otherCountries.push(obj);
      }
    })

    // sort popular countries array w.r.t featured array
    this.popularCountries.sort( (a, b) => {
      return this.featuredCountryCodes.indexOf(a.attributes.iso_code) - this.featuredCountryCodes.indexOf(b.attributes.iso_code);
    });

    // alphabetically sort other countries
    this.otherCountries.sort(function (a, b) {
      var textA = a.attributes.name.toUpperCase();
      var textB = b.attributes.name.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    allCountries = this.popularCountries.concat(this.otherCountries);
    if(res.ip_country){
      localStorage.setItem("userCountry", JSON.stringify({attributes: res.ip_country}));
    }
    else{
      localStorage.setItem("userCountry", JSON.stringify(allCountries[0]));
    }
    return {
      data: allCountries,
      ip_country: res.ip_country ? res.ip_country : null
    };
  }
  else{
    return [];
  }

}
  getAllCountries() {
  
    return this.http.get(environment.corePathV2 + "/countries").pipe(
      map(res => this.processCountries(res)),
      catchError(error => throwError(error))
    );

  }
  featuredCountryCodes = ['IND', 'SGP', 'ARE', 'AUS', 'USA', 'GBR', 'CAN', 'SAU', 'KWT', 'QAT', 'BHR', 'OMN', 'HKG', 'NZL', 'BGD'];
  popularCountries = [];
  otherCountries = [];
  allCountries: any = [];

  filteredCountries = [];
  countrySearchTerm = '';
  filterItem(searchTerm:string){
   if(searchTerm && searchTerm.length > 0){
      this.filteredCountries = Object.assign([], this.allCountries).filter(
        (item: any) => item.attributes.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
     )
   }
  }

  public isDeviceMobile(): boolean {
    return window.innerWidth <= 768;
  }

}
