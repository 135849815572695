import { LoginConfigOverride } from '@pv-frontend/authentication';

// TODO: [Important] add config to change styles for desktop which are configurable

export const journeyConfig: {
  authentication: LoginConfigOverride
} = {
  authentication: {
    authenticationFlow: {
      config: {
        flow: [
          'MOBILE_SEARCH',
          'OTP_VERIFICATION',
          'CARD_SELECTION',
        ],
      },
    },
    drawer: {
      config: {
        dismissOnBackdropClick: true,
      },
    },
    binValidation: {
      config: {
        skipBinValidation: true,
        heading: '',
        shouldValidate: false,
        binType: 'first8',
        errorMessage: '',
        submitButtonLabel: 'Continue',
        binSeparator: '',
      },
      style: {
        binInput: {
          input: {
            width: '25%',
            padding: '20px',
            height: '48px',
          },
          label: {
            fontSize: '0.875rem',
          },
        },
        pciImage: {
          width: '2rem',
        },
        pciLabel: {
          fontSize: '12px',
        },
        button: {
          fontSize: '16px',
        },
        backIcon: {
          width: '20px',
          height: '20px',
          fill: 'var(--surface-action-icon-low-contrast)',
        },
      }
    },
    mobileSearch: {
      config: {
        skipMobileSearch: false,
        heading: 'Enter your mobile number',
        mobileSearchErrorMessage: 'Please enter the registered mobile number',
        otpTriggerFailureMessage: 'You have exceeded the maximum limit for OTP requests. Please try again after some time.',
        submitButtonLabel: 'Send OTP',
        showBackIcon: false,
        shouldCreateUser: true,
      },
      style: {
        mobileInput: {
          container: {
            display: 'block',
            width: '100%',
          },
          inputBox: {
            height: '48px',
          },
          error: {
            marginTop: '8px',
            flexFlow: 'wrap',
            display: 'flex',
            width: '100%',
          },
        },
        backIcon: {
          width: '20px',
          height: '20px',
          fill: 'var(--surface-action-icon-low-contrast)',
        },
        label: {
          fontSize: '0.875rem',
        },
        button: {
          fontSize: '16px',
        },
      },
    },
    otpVerification: {
      config: {
        skipOtpVerification: false,
        heading: 'Enter OTP',
        errorMessage: 'Invalid OTP. Please enter the correct OTP.',
        resendOtpLabel: 'Did not receive OTP yet?',
        editMobileBtnLabel: 'Change number ?',
        countdownValue: 180,
        submitButtonLabel: 'Verify OTP',
      },
      style: {
        resentOtpLabel: {
          width: '2rem',
        },
        otpInput: {
          input: {
            width: 'inherit',
            maxWidth: '4rem',
          },
          container: {
            width: '100%',
          },
          inputContainer: {
            width: '100%',
          },
          label: {
            fontSize: '0.875rem',
          },
        },
        coutdown: {
          container: {
            fontSize: '0.75rem',
          },
        },
        button: {
          fontSize: '16px',
        },
      },
    },
    userCardSelection: {
      style: {
        continueButton: {
          borderRadius: '.125rem',
        },
        heading: {
          fontSize: '0.875rem',
        },
        cardTitle: {
          '-webkit-line-clamp': 2,
        },
      },
      config: {
        skipCardSelection: false,
        heading: 'Select your card',
        allowAddCard: true,
        addNewCardLabel: 'Add new card',
      },
    },
    addCard: {
      config: {
        heading: 'Enter first 8 digits of your card',
        errorMessage: '',
        submitButtonLabel: 'Add Card',
        binSeparator: '',
        binType: 'first8',
      },
      style: {
        binInput: {
          input: {
            width: '25%',
            padding: '20px',
            height: '48px',
            fontSize: '13px',
          },
          label: {
            fontSize: '0.875rem',
          },
        },
        backIcon: {
          width: '20px',
          height: '20px',
          fill: 'var(--surface-action-icon-low-contrast)',
        },
        pciImage: {
          width: '2rem'
        }
      },
    },
  },
};
