import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from '@pv-frontend/authentication';
import { BinValue } from '@pv-frontend/pv-shared-components/bin-input';
import { PaymentService } from '../../../pages/payment/service/payment.service'
import { PV_USER_SERVICE, PvUserService } from '@pv-frontend/pv-shared-services/pv-user-service';

interface BinInputForm {
  binControll: FormControl<BinValue | null>;
}

@Component({
  selector: 'app-payment-verification',
  templateUrl: './payment-verification.component.html',
  styleUrls: ['./payment-verification.component.scss']
})
export class PaymentVerificationComponent {

  public isPaymentVerificationConfirmed: boolean = false;
  public paymentForm = this.fb.group<BinInputForm>({
    binControll: new FormControl(
      {
        bin: '',
        binType: 'last4',
      },
      Validators.required
    ),
  });
  public isBinInvalid: boolean = false;
  public isBinIncorrect: boolean = true;
  public binInputStyle = {
    binInput: {
      input: {
        width: '25%',
        padding: '20px',
        height: '48px',
      },
      label: {
        fontSize: '0.875rem',
      },
    },
    pciImage: {
      width: '2rem',
    },
    pciLabel: {
      fontSize: '12px',
    },
    button: {
      fontSize: '16px',
    },
    backIcon: {
      width: '20px',
      height: '20px',
      fill: 'var(--surface-action-icon-low-contrast)',
    },
  }
  public isPaymentInProgress: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private paymentService: PaymentService,
    @Inject(PV_USER_SERVICE) private pvUserService: PvUserService,
  ) {
    this.subscribeToBinChange();
    this.paymentService.paymentClickedEvent.subscribe((isLoading) => {
      this.isPaymentInProgress = isLoading;
    })
    this.paymentService.paymentModalClosedEvent.subscribe((isClosed) => {
      if(isClosed) {
        this.isPaymentInProgress = false;
      }
    })
  }

  private subscribeToBinChange(): void {
    this.paymentForm.controls.binControll.valueChanges.subscribe(
      (bin: BinValue | null) => {
        this.isBinIncorrect = bin ? !this.checkBinValidity(bin) : true;
      }
    );
  }

  public checkBinValidity(binValue: BinValue): boolean {
    if (!binValue.bin) return false;
    return binValue.bin.split("_").join("").length === 8;
  }

  public initiatePennyDropPayment(): void {
    if (
      this.paymentForm.valid &&
      this.paymentForm.controls?.binControll?.value &&
      this.checkBinValidity({
        ...this.paymentForm.controls?.binControll?.value,
      })
    ) {
      const userDetails = this.pvUserService.getUserDetails();
      this.paymentService.initiatePayment({
        amount: 1,
        vcp: 'vp',
        first_name: userDetails.first_name,
        email: userDetails.email,
        phone: userDetails.mobile,
        product_info: ''
      }, "39aea870-9384-4a3d-b015-b2502cde8355");
      // COMMENT: projectID to be passed for penny drop
    }
  }

}
