<div>
  <div class="modal-content">
    <div class="close-box pointer logo mar-bot-25 flex  align-center"     [ngClass]="GlobalConstants.data.client == 'visa' ? 'space-between' : 'justify-end'"    >
      <img *ngIf="GlobalConstants.data.client == 'visa'" src="https://images.poshvine.com/logos/visasl.png" class="img-fluid login-client-logo">
      <svg (click) = "closeModalRef()" class="c-icon" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9309 1.89109L15.2749 0.246094L8.70957 6.76776L2.14428 0.246094L0.488281 1.89109L7.05357 8.41276L0.488281 14.9344L2.14428 16.5794L8.70957 10.0578L15.2749 16.5794L16.9309 14.9344L10.3656 8.41276L16.9309 1.89109Z" fill="#ADB5BD"/>
      </svg>
    </div>

    <section class="step-one" *ngIf="stepToShow == 'mobile'">
      <div class="d-flex flex-column">
        <span class="f500 fnt-20">Explore personalised benefits for</span>
        <span class="fnt-16 card-name" *ngIf="loginCardDetails?.name || loginCardDetails?.card_type">
          {{loginCardDetails?.name}} {{loginCardDetails?.card_type}} Card
        </span>
      </div>
      <form [formGroup]="createWithUserForm" (submit)="userSearch()" autocomplete="newpassword">

        <div class="fields">
          <div class="">
            <div class="clearfix field-wrap pad-top-15">
              <label class="mar-bot-17 f500 fnt-16">Enter your mobile number <span *ngIf = "!ifExistingUser"> and email address </span>here.</label>
              <div class="mobile-block flex relative">
                <div class="country-block flex align-center justify-center h100">
                  <div class="searchdrop relative" dropdown #dropdown="bs-dropdown" [insideClick]="true">
                      <div class="w100 h100 flex align-center pointer fnt-16 f500" dropdownToggle>
                        <div class="custom-select-box relative" *ngIf="selectedCountry">
                          <div class="btn filter-btn flex align-center">
                            <img [src]="'https://images.poshvine.com/flags/' + selectedCountry?.iso_code.toLowerCase() + '.jpg'" alt="Flag" class="filter-ic relative selected-country-flag" />

                              <svg class="mar-left-6 mar-top-2" width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 4L0 0L8 6.28116e-07L4 4Z" fill="#98A2B4" />
                              </svg>

                          </div>
                        </div>

                      </div>
                      <div class="dropdown-menu dropdown-menu-shadow country-list mar-top-6 pad-top-0" *dropdownMenu>
                        <div class="select-input-parent pad-8 w100 z1 bg-fff">
                          <input autocomplete="new-password" class="pad-left-29 country-input form-control fnt-13" [(ngModel)] = "countrySearchTerm" [ngModelOptions]="{standalone: true}" (input) = "filterItem(countrySearchTerm)" placeholder="Search country">
                          <svg class="absolute se-ic" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M10.0994 20.1942C12.5711 20.1935 14.9558 19.2805 16.7974 17.6299L22.9867 23.8266C23.0982 23.9378 23.2492 24.0001 23.4065 24C23.5639 23.9998 23.7148 23.9372 23.826 23.8258C23.9373 23.7144 23.9999 23.5634 24 23.4058C24.0001 23.2483 23.9379 23.0971 23.8268 22.9855L17.6374 16.7888C19.1667 15.0587 20.0571 12.856 20.1601 10.5481C20.2632 8.24013 19.5726 5.96661 18.2037 4.10686C16.8347 2.2471 14.8702 0.913592 12.6379 0.328823C10.4055 -0.255945 8.0404 -0.0566021 5.93713 0.893592C3.83385 1.84379 2.11964 3.48736 1.08051 5.55008C0.0413807 7.6128 -0.259817 9.9699 0.227173 12.2281C0.714163 14.4863 1.95989 16.509 3.75649 17.9587C5.55309 19.4084 7.79192 20.1975 10.0994 20.1942ZM10.0994 1.16102C11.8619 1.16102 13.5848 1.68428 15.0502 2.66462C16.5156 3.64496 17.6578 5.03835 18.3323 6.6686C19.0067 8.29885 19.1832 10.0927 18.8394 11.8234C18.4955 13.5541 17.6468 15.1438 16.4006 16.3915C15.1543 17.6392 13.5665 18.489 11.8379 18.8332C10.1093 19.1775 8.31754 19.0008 6.68923 18.3255C5.06092 17.6502 3.66918 16.5067 2.69001 15.0395C1.71084 13.5723 1.1882 11.8474 1.1882 10.0828C1.19086 7.71744 2.13057 5.44969 3.80117 3.7771C5.47176 2.10451 7.73682 1.16368 10.0994 1.16102Z"
                              fill="#777" />
                          </svg>
                        </div>
                          <div class="block loc-results">
                            <div *ngFor="let country of filteredCountries; let i = index" (click)="dropdown.isOpen = false;countrySearchTerm = '';setCountryByCode(country?.attributes?.iso_code)" [ngClass]="{'selected-country': country.attributes?.iso_code == selectedCountry.iso_code}">
                              <div class="flex relative country-item pointer">
                                <div class="country-img">
                                  <img [src]="'https://images.poshvine.com/flags/' + country.attributes.iso_code.toLowerCase() + '.jpg'" class="img-fluid">
                                </div>
                                <div class="item-name flex align-center c0E202E fnt-13 w100">
                                  <span>{{country.attributes.name}}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>

                  </div>

                </div>
                <div class="mobile-input relative">
                  <span class="absolute country-code fnt-15 f500">+{{selectedCountry.calling_codes[0]}}</span>
                  <input autofocus formControlName="mobile" class="form-control h100 mobile-inp fnt-15 f500 " [(ngModel)]="mobNum" type="text"
                    placeholder="Mobile Number" maxlength="{{selectedCountry?.max_mobile_digit}}"
                    minlength="{{selectedCountry?.min_mobile_digit}}"
                    (input)="removeLetter($event.target.value);debouncedUserSearch($event);"
                    [ngClass] = "selectedCountry.calling_codes[0].toString().length === 1 ? 'dig-1' : (selectedCountry.calling_codes[0].toString().length === 2 ? 'dig-2' : 'dig-3')"/>
                  <div class="small-loader input-load" *ngIf="searchLoader"></div>
                </div>

              </div>

            </div>
            <div class="mobile-block mar-top-20 flex relative" *ngIf = "!ifExistingUser">
              <div class="icon-block flex align-center justify-center h100">
                <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.522592 1.51453C3.96134 4.40742 9.99528 9.49691 11.7687 11.0838C12.0068 11.298 12.2622 11.4069 12.5272 11.4069C12.7918 11.4069 13.0467 11.299 13.2842 11.0859C15.0592 9.4974 21.0932 4.40742 24.532 1.51453C24.746 1.33476 24.7787 1.01879 24.6054 0.799021C24.2047 0.29118 23.6073 0 22.967 0H2.08753C1.44729 0 0.849829 0.291181 0.449187 0.79907C0.275855 1.01879 0.308496 1.33476 0.522592 1.51453Z" fill="#CED4DA"/>
                  <path d="M24.7526 3.08228C24.5676 2.99672 24.3499 3.02657 24.196 3.15723C20.3824 6.36858 15.5153 10.4849 13.984 11.8556C13.1246 12.6262 11.9317 12.6262 11.0703 11.8545C9.43804 10.3937 3.97245 5.77815 0.859419 3.15719C0.704438 3.02652 0.48628 2.99769 0.302769 3.08223C0.118279 3.16735 0 3.35066 0 3.55269V16.5926C0 17.7365 0.936396 18.6667 2.08796 18.6667H22.9675C24.119 18.6667 25.0554 17.7365 25.0554 16.5926V3.55269C25.0554 3.35066 24.9371 3.16686 24.7526 3.08228Z" fill="#CED4DA"/>
                </svg>
              </div>
              <div class="mobile-input relative">
                <input formControlName="email" class="form-control h100 email-inp" type="text" placeholder="E-mail address"
                  (input)="errorRequestingOtpMsg = ''; isload1 = false;" />
                <div class="small-loader input-load" *ngIf="searchLoader"></div>
              </div>
              <div class="email-input-err absolute" *ngIf = "invalidEmail">
                <div class="error-txt  fnt-12">Please enter a valid email</div>
              </div>
            </div>
            <div class="clearfix relative">
              <div class="error-txt mar-top-10" *ngIf="invalidUser">Invalid credentials</div>
            </div>
            <div class="clearfix relative">
              <div class="error-txt mar-top-10" *ngIf="errorRequestingOtp">{{errorRequestingOtpMsg === 'invalid_user_details' ? 'Invalid credentials' : errorRequestingOtpMsg}}</div>
            </div>
            <section>
              <div class="clearfix continue-btn-block mar-top-10">
                <button class="relative main-continue btn brand-btn" type="submit" *ngIf="mobileInputBtnActive"
                  [disabled]="isload1">Continue <div class="small-loader input-load card-loader" *ngIf="isload1"></div></button>
                <button type="btn" class="btn" *ngIf="!mobileInputBtnActive" disabled="disabled">Continue</button>
              </div>
            </section>

          </div>
        </div>
      </form>
    </section>
    <section class="step-two otp-input" *ngIf="stepToShow == 'otp'">
      <form [formGroup]="validateUserOtpForm">
        <div class="title" style="font-size: 16px;">
          <span> Enter the 6 digit OTP sent to <span class="pad-right-4" *ngIf = "selectedCountry && selectedCountry.calling_codes && selectedCountry.calling_codes[0]">+{{selectedCountry.calling_codes[0]}}</span>
            *******{{mobNum | slice:7:10 }} </span>
          <span class="back-btn pointer relative fnt-13 pad-left-5"
             (click)="resetLoginAgain();stepToShow = login_steps[1];currentStep = 1;">(Edit)</span>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="otp-field">
              <input formControlName="otp" id="otp-field" class="form-control" autocomplete="off"
                (input)="validateUserOtp(); invalidOtp = false;" max="6" maxlength="6" [(ngModel)]="otpInp" />
              <div class="input-border flex">
                <span class="num-1" [ngClass]="{'active' : otpInp?.length == 0 || !otpInp}"></span>
                <span class="num-2" [ngClass]="{'active' : otpInp?.length == 1}"></span>
                <span class="num-3" [ngClass]="{'active' : otpInp?.length == 2}"></span>
                <span class="num-4" [ngClass]="{'active' : otpInp?.length == 3}"></span>
                <span class="num-5" [ngClass]="{'active' : otpInp?.length == 4}"></span>
                <span class="num-6" [ngClass]="{'active' : otpInp?.length == 5}"></span>
              </div>
              <div class="just"></div>
              <div class="check" *ngIf="validOtp">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0ZM6 11.25L2.25 7.5L3.3075 6.4425L6 9.1275L11.6925 3.435L12.75 4.5L6 11.25Z"
                    fill="#15C9B7" />
                </svg>
              </div>
              <div class="clear pointer" (click)="clearOtp(); invalidOtp = false" *ngIf="!otpVerifying && !validOtp">
                X
              </div>
              <div class="clear otp-verifying" *ngIf="otpVerifying">
                <img class="loader" src="./assets/img/spin.gif">
              </div>
            </div>
            <div class="help-block f500 fnt-13 mar-top-12" *ngIf="viewTimer">Resend Code in {{timerToShow}}</div>
            <div class="check error-txt" *ngIf="invalidOtp">
              Entered Otp is invalid.
            </div>
            <div class="check error-txt" *ngIf="errorRequestingOtp">
              {{errorRequestingOtpMsg}}
            </div>
            <button

              (click)="clearOtp(); requestPoshvineOtp(false); invalidOtp = false; errorRequestingOtp = false;"
              class="resend-otp pointer fnt-12 f400 mar-top-4" *ngIf="!viewTimer || viewTimer.length == 0">Resend
              Code</button>
          </div>
        </div>
      </form>
    </section>

    <section class="step-three"
      *ngIf="stepToShow == 'card'">
      <form [formGroup]="addCardForm" (submit)="addCardFormSubmit()">


        <div class="card-block pad-top-5">
          <label class="mar-bot-20 fnt-16 lbl f600">Enter first 8 digits of your  {{GlobalConstants.data.client == 'visa' ? 'Visa' : 'eligible'}} Card</label>

          <div class="card-block">

            <div class="pad-bot-10">Card Number</div>
            <div class="flex main-flex">
              <div class="flex-3 pd-r7">
                <input type="text" #firstFocusable id="first_four_card_digits" class="form-control"
                  (keyup)="onKeyph($event); keytab($event, focusable)" formControlName="binFirst4"
                  autocomplete="new-password" minlength="4" maxlength="4" (input)="checkbtn()" required [ngClass]="{'is-invalid': binError}">
              </div>
              <div class="flex-3 pd-l7">
                <input type="text" #focusable id="last_four_card_digits" class="form-control" formControlName="binLast4"
                  autocomplete="new-password" minlength="4" maxlength="4"
                  (keyup)="onKeyph($event);backkeytab($event, firstFocusable)" (input)="checkbtn()" required [ngClass]="{'is-invalid': binError}">
              </div>
              <div class="flex-3 pd-r7">
                <div class="form-single-container">
                  <input type="text" class="form-control text-center disabled" placeholder="XXXX" autocomplete="none"
                    disabled>
                </div>
              </div>
              <div class="flex-3 pd-r7">
                <div class="form-single-container">
                  <input type="text" class="form-control text-center disabled" (keyup)="onKeyph($event)"
                    placeholder="XXXX" disabled>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class=" pad-bot-20 clearfix">
          <div class="error-txt fnt-12 pad-top-10" *ngIf="binError">
            <span *ngIf = "notApplicableCard">Invalid! Please check the card number you have entered and try again.</span>
            <span *ngIf = "!notApplicableCard">Dear customer, please re-check the first 8 digits of the card number you have entered</span>
          </div>

          <div class = "continue-btn-block" *ngIf="binError">
            <span class="btn btn-theme try-again brand-btn relative" (click)="clearCardInput()">Try Again</span>
          </div>
          <div class = "continue-btn-block" *ngIf="!binError">
            <button class="btn btn-theme " *ngIf="!btnActive" type="submit" disabled="disabled">Continue</button>
            <button id="gtm-login-card-continue-btn" class="btn btn-theme relative brand-btn" *ngIf="btnActive" type="submit"
              (click)="showIfNotAuthenticated = true">Continue
              <div class="small-loader input-load card-loader" *ngIf="isload2"></div>
            </button>
          </div>

        </div>
        <div class="flex last-b">
          <div class="ll">Your Card/ Account parameters are used only to check eligibility and are encrypted</div>
          <div class="rr">
            <img src="https://images.poshvine.com/logos/PCI-DSS-PoshVine.png">
          </div>

        </div>
      </form>
    </section>


  </div>
</div>
