<app-header></app-header>


<main>
  <!-- <p>main-layout works!</p> -->
  <router-outlet></router-outlet>
</main>

<app-footer></app-footer>
<pv-authentication [isVisible]="showAuthenticationModal"></pv-authentication>
<app-loader *ngIf="isValidatingCard"></app-loader>