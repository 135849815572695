import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular-ivy";
 import packageJson from "../package.json";

 Sentry.init({
   dsn: environment.sentryDsn,
   release: packageJson.version,
   integrations: [
     Sentry.browserTracingIntegration(),
     Sentry.replayIntegration({
       maskAllText: false,
       blockAllMedia: false,
     }),
   ],
   // Performance Monitoring
   tracesSampleRate: 0.2, //  Capture 20% of the transactions
 });

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
