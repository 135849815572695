import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { AuthService } from '../../services/auth/auth.service';
import { appConstants } from 'src/app/shared/constants/app.constants';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common/common.service';
import { GlobalConstants } from '../../services/common/global-variables.service';
import { CardStatuses } from 'src/app/interfaces/auth.types';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() cards;
  @Input() selectedCard;
  @Output() emitLogin = new EventEmitter<boolean>();
  @Output() emiLsidebarClose = new EventEmitter<boolean>();
  @Input() sidebarOpen: boolean = true;
  menuItems = appConstants.sideBarItems;
  public CardStatuses = CardStatuses;

  constructor(
    public _authService: AuthService,
    private router: Router,
    public _commonService: CommonService,
    public GlobalConstants: GlobalConstants
  ) { }

  ngOnInit(): void {

  }


  setCurrentUserCards(card) {
    this.validateCard(card.attributes.bin, card.attributes.bin_type);
  }

  validateCard(bin, bin_type) {
    this._authService.validateCard(bin, bin_type).subscribe(res => {
      if (res && res.data && res.data.attributes.bin == bin && (res.data.attributes.status == CardStatuses.bin_verified || res.data.attributes.status == CardStatuses.payment_verified)) {
        let current_user = JSON.parse(localStorage.getItem("currentUser"));
        current_user.token = res.data.attributes.session_token;
        localStorage.setItem('currentUser', JSON.stringify(current_user));
        localStorage.setItem('card_verified', "true");
        localStorage.setItem('uc', JSON.stringify(res.data.id));
        location.reload();
      }
      else {
        localStorage.setItem('card_verified', "false");
      }
    })
  }
  logout() {
    this._authService.logout();
  }
  openLoginModal() {
    if (!this._authService.isAuthenticated()){
      this.closeSidebar()
      this._commonService.openLoginModal();
    }
  }
  closeSidebar (){
    this.sidebarOpen = false;
    this.emiLsidebarClose.emit(false)
  }
  goto(link){
    this.closeSidebar();
    if (link === 'logout'){
      this.logout();
    }
    else{
      this.router.navigate([link], {queryParamsHandling: "preserve"});
    }

  }

}
