// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.



export const environment = {
  production: false,
  sentryDsn: "https://53ee68103428e02b8b0e4a75191d8adb@o515678.ingest.us.sentry.io/4507412130496512",
  SENTRY_ORG: "rzp",
  SENTRY_PROJECT: "collinson-pv",
  SENTRY_AUTH_TOKEN: "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",
  corePathV2: "https://sandbox-api.poshvine.com/cs/v1/",
  giftPathV2: "https://sandbox-offerservice.poshvine.com/offer_service/v2/",
  offerPathV2: "https://sandbox-api.poshvine.com/ps/v1/",
  apiUrlBsV1: "https://sandbox-api.poshvine.com/bs/v1/",
  apiUrlPaV1: "https://sandbox-api.poshvine.com/pa/v1/",
  apiUrlLsV1: "https://sandbox-api.poshvine.com/ls/v1/",
  baseUrl: "https://sandbox-api.poshvine.com/",
  apiUrlBsV1Internal: 'https://sandbox-api.poshvine.com/bs/internal/',
  apiUrl: 'https://rewardshub-stage-api.poshvine.com/api/v1/',
  stagingUrl: 'https://rewardshub-stage-api.poshvine.com/',
  perksUrl: 'https://sandbox-api.poshvine.com/ps/rewardshub/',
  rewardsHubApiUrl: 'https://rewardshub-stage-api.poshvine.com/api/v1/',


  clientId: '8b431f8b-b97f-45fb-acd9-3850d2b4888a',
  // clientId: '09fc67f1-b0f7-486e-9ef4-54cb6b74e89f',
  projectId: 'e83c513f-e29a-46d3-b00d-3ef45f18f163',
  // projectId: '532792e1-c583-4437-b6e0-a5827597568b',
  v2ApiBs: 'https://sandbox-api.poshvine.com/bs/v1',
  baggagePorterMerchantId: 'e6b983c0-c515-4cf8-a34f-234ffd2d0615',
  apiClientsServiceUrl: 'https://sandbox-api.poshvine.com/cs/v1',
}


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
