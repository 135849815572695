<section id="sidebar" [class.sidebarOpen] = "sidebarOpen">
  <section class="head-sec flex align-center space-between pad-left-20 pad-right-20">
    <div>
      <div class="fnt-18 f600 pad-bot-10 welcome">Welcome!</div>
      <div class="d-flex justify-content-start align-items-center login-btn">
        <span class="icon-bg  mar-right-10" (click)="openLoginModal()">
          <span class="icon icon-card-2 fnt-26 icon-color"></span>
        </span>
        <div *ngIf="!_authService.isAuthenticated()" (click)="openLoginModal()"
          class="d-flex flex-column justify-content-between align-items-start login-txt pointer">
          <span class="headline f600 fnt-14 card-num">{{GlobalConstants.data.prelogin_text}}</span>
          <span class="d-none d-lg-block sub-headline mar-top-4 fnt-13 ">Sign in with your card to avail benefits</span>
          <span class="d-block d-lg-none sub-headline mar-top-4 card-txt">Sign in & avail benefits</span>
        </div>
        <div *ngIf="_authService.isAuthenticated()"
          class="d-flex flex-column justify-content-between align-items-start card-data pointer">
          <div>
            <div class="fnt-14 f500 card-num">{{selectedCard?.attributes?.bin | slice:0:4 }} {{selectedCard?.attributes?.bin | slice:-4 }} XXXX XXXX</div>
            <div class="fnt-13 card-txt" [innerHtml]="selectedCard?.attributes?.display_name"></div>
          </div>

        </div>
      </div>
    </div>
    <a routerLink="/" class="mar-left-0">
      <img class="logo" src="https://images.poshvine.com/collinson/Access+India_RGB_Digital+.svg" alt="logo">
    </a>
  </section>
  <section class="flex justify-end pad-20 pad-top-15 pad-bot-15 close-contain">
    <span (click)="closeSidebar()" class="close-icon flex align-center justify-center pointer">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.76 1.40022L10.5998 0.23999L5.99999 4.83976L1.40022 0.23999L0.23999 1.40022L4.83976 5.99999L0.23999 10.5998L1.40022 11.76L5.99999 7.16022L10.5998 11.76L11.76 10.5998L7.16022 5.99999L11.76 1.40022Z"
          fill="#64707C" />
      </svg>
    </span>
  </section>
  <section class= "note-line fnt-12 pad-20 pad-top-12 pad-bot-12">
    To avail the benefits applicable to your travel itinerary, please select the travel details
  </section>

  <section class="pad-top-25 menu-list">
    <ng-container *ngFor = "let item of menuItems; let i = index">
      <div class="menu-item mar-bot-2" [class.hide] = "!_authService.isAuthenticated() && (item.name == 'Redemption History' || item.name == 'Log Out')">
        <a (click) = "goto(item.link)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true }"
          class="flex align-center pad-top-10 pad-bot-10 pad-20 pointer">
          <div [innerHtml]="item.img | safe"></div>
          <div [innerText]="item.name" class="pad-left-13 fnt-17 pad-top-4 menu-txt"></div>
        </a>
      </div>
    </ng-container>

  </section>
</section>
<div class="modal-backdrop fade in show" *ngIf = "sidebarOpen" (click) = "closeSidebar()"></div>
