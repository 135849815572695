import { CommonService } from './common/common.service';
import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import { PV_USER_SERVICE, PvUserService } from '@pv-frontend/pv-shared-services/pv-user-service';
import { AuthService } from '../services/auth/auth.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    // private authenticationService: AuthenticationService,
    @Inject(PV_USER_SERVICE) public pvUserService: PvUserService,
    private commonService: CommonService,
    private authService: AuthService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        event => { },
        error => {

          if (error.status == 401) {
            localStorage.clear();
            sessionStorage.clear();
            this.pvUserService.toggleAuthModal(true);
            this.authService.userLoggedIn$.next(false);
          }
          if (error instanceof HttpErrorResponse) {
            return throwError(error);
          }
        }
      )
    );
  }
}
