import { GlobalConstants } from './../common/global-variables.service';
import { Inject, Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { LoginComponent } from '../../components/login/login.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from "@angular/common";
import { PV_USER_SERVICE, PvUserService } from '@pv-frontend/pv-shared-services/pv-user-service';


const APIsToExclude = [
  'hyd.carterx.in'
]
@Injectable({
  providedIn: "root"
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    @Inject(PV_USER_SERVICE) private pvUserService: PvUserService,
    @Inject(DOCUMENT) private document: any,
    public GlobalConstants: GlobalConstants
  ) { }
  modalref: BsModalRef;
  environment_type: any;
  projectId: string = '';

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const isApiExcluded = APIsToExclude.some(api => request.url.includes(api));
    if(!isApiExcluded) {
      this.environment_type = this.document.location.hostname.includes("sandbox") ? 'sandbox' : 'prod';
      this.projectId = this.GlobalConstants.data.x_header[this.environment_type].project_id;

      request = request.clone({
        setHeaders: {
          'X-Client-Id': environment.clientId,
        }
      });

      if(!request.headers.get('X-Project-Id')) {
        request = request.clone({
          setHeaders: {
            'X-Project-Id': this.projectId
          }
        });
      }

      if(request.headers.get('X-Country-Id')){

      }
      // else if(localStorage.getItem("userCountry")){
      //   let userCountry = JSON.parse(localStorage.getItem('userCountry'));
      //     if(userCountry){
      //       request = request.clone({
      //         setHeaders: {
      //           'X-Country-Id': userCountry.id ? userCountry.id : userCountry.attributes.id
      //         }
      //       });
      //     }
      // }
      var currentUser;
      if (localStorage.getItem('currentUser')) {
        currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser.token) {
          request = request.clone({
            setHeaders: {
              'Authorization': `Bearer ${currentUser.token}`
            }
          });
        }
      } else if (this.pvUserService.getTempToken()) {
        request = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${this.pvUserService.getTempToken()}`
          }
        });
      }
    }
    return next.handle(request).pipe(
      tap(
        (response: HttpEvent<any>) => {
          // console.log(response);
        },
        (error: HttpErrorResponse) => {
          // if (error["status"] == 401){
          //   this._authService.logout();

          //   if (!error.url.endsWith('/cs/v1/user') && !error.url.endsWith('/user/user_cards')) {
          //     var config = {
          //       backdrop: true,
          //       ignoreBackdropClick: false,
          //       class: 'login-modal'
          //     };
          //     this.modalref = this.modalService.show(LoginComponent, Object.assign({}, config, {}));
          //     this.modalref.content.closeModal.subscribe(res => {
          //       this.modalref.hide();
          //     })
          //   }
          // }
          // else if (error["status"] != 200 && error["status"] != 401 && error["status"] != 404) {
          //   // this.router.navigate(["error"]);
          // }
        }
      )
    );
  }

}
