<footer class="container">
  <div class="d-flex align-items-center justify-content-between top-sec">
    <div class="d-flex align-items-center">
      <img class="logo" src="https://images.poshvine.com/collinson/Access+India_RGB_Digital+.svg">
      <span class="fnt-12 accenttwo mar-left-custom">
        © {{currentYear}}
      </span>
    </div>
    <div class="d-flex align-items-center justify-content-start">
      <a href="/info/terms-of-use" target="_blank" rel="noreferrer noopener">Terms of Use</a>
      <a href="/info/conditions-of-use" target="_blank" class="mar-left-custom" rel="noreferrer noopener">Conditions of Use</a>
      <a href="/info/privacy-policy" target="_blank"  class="mar-left-custom" rel="noreferrer noopener">Privacy Policy</a>
      <a href="/info/customer-support" target="_blank" class="mar-left-custom" rel="noreferrer noopener">Customer Support</a>
    </div>
    <!-- <div class="flex flex-end  pad-bot-1 flex-r">

      <div class="pci-logo flex lg"><img [src]="'./assets/img/other/pci.webp'" alt="pci-dss-logo"></div>
    </div> -->
  </div>
  <div class="bottom-sec mt-2">
    <div class="disclaimer-text" style="color: var(--black-1); width: 70%;">
      <b>Disclaimer :</b> {{GlobalConstants.data.footer_text}}
    </div>
    <div class="d-flex flex-end pad-bot-1 flex-r ml-auto">

      <div class="pci-logo flex lg"><img [src]="'./assets/img/other/pci.webp'" alt="pci-dss-logo"></div>
    </div>
  </div>
  <!-- <div class="d-flex align-items-center justify-content-start pt-2 pb-2">
    <a href="/info/privacy-policy" target="_blank" rel="noreferrer noopener">Privacy Policy</a>
    <a href="/info/terms-and-conditions" target="_blank" class="mar-left-custom" rel="noreferrer noopener">Terms & Conditions</a>
    <a href="/info/customer-support" target="_blank" class="mar-left-custom" rel="noreferrer noopener">Customer Support</a>
  </div> -->
</footer>
