<section class="d-flex flex-column card-verification-container align-items-center px-4 py-5 justify-content-center">

    <ng-container *ngIf="isValidatingCard; else paymentStatus">
        <img src="https://images.poshvine.com/icon/card_verifying.svg" class="card_verifying" />
        <h6 class="mt-5">Verifying your card</h6>
        <span class="text-base mt-2">Sit back and relax...</span>
    </ng-container>
    <ng-template #paymentStatus>
        <pv-svg-icon [name]="isPaymentVerified ? 'circular-tick' : 'circular-cross-fill'" [class]="isPaymentVerified ? 'success-img' : 'failed-img'"></pv-svg-icon>
        <h6 class="mt-5">{{isPaymentVerified ? 'Card verified successful': 'Card verification failed'}}</h6>
        <span class="text-base mt-2">Redirecting you to {{isPaymentVerified ? 'the redemption history page' : 'home page'}}</span>
    </ng-template>

</section>