import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpParams, HttpHeaders, HttpBackend } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: "root"
})
export class AuthService {

  public userLoggedIn$ = new Subject<boolean>();

  // Observable for user login/logout events
  public userAuthenticatedObservable$: Observable<boolean> = this.userLoggedIn$.asObservable();

  public currentUserSubject: BehaviorSubject<any>;
	constructor(
		private http: HttpClient,
    private router: Router
	) {
      this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
	}
	reloadTemplate = new EventEmitter<any>();
  get(endpoint, params?) {
		return this.http.get(endpoint, params);
	}

	post(endpoint, body, params?) {
		return this.http.post(endpoint, body, params);
	}

	delete(endpoint, params?){
		return this.http.delete(endpoint, params);
	}
	getUser(userId) {
		return this.http.get<any>(environment.corePathV2 + 'users/' + userId, {})
			.pipe(
				map(res => res || res),
				catchError(error => throwError(error))
			);
	}
	getUserDetails() {
		return this.http.get<any>(environment.corePathV2 + 'user', {})
			.pipe(
				map(res => res || res),
				catchError(error => throwError(error))
			);
	}

  updateUserCreds(params) {
    return this.http.put(environment.corePathV2 + "/user", params).pipe(
      map(res => res || []),
      catchError(error => throwError(error))
    );
  }
	getCards(params = {}) {
		return this.http.get<any>(environment.corePathV2 + 'user/user_cards', {
			params: params
		})
			.pipe(
				map(res => res || []),
				catchError(error => throwError(error))
			);
	}



	binValidation(bin) {
		return this.http.get<any>(environment.corePathV2 + "bins/card_details?bin=" + bin).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		)
	}
	cardValidation(bin) {
		return this.http.post<any>(environment.corePathV2 + "bins/validate", {bin: bin}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		)
	}
	addCard(bin, bin_type ){
		return this.http.post<any>(environment.corePathV2 + "user/user_cards", {bin: bin, bin_type : bin_type }).pipe(
		  map(res => res || []),
		  catchError(error => throwError(error))
		);
	}
	validateCard(bin, bin_type = "first8") {
		return this.http.post<any>(environment.corePathV2 + "user/user_cards/search",{bin:bin, bin_type:bin_type}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}







  addLocation(params) {
    return this.http.post<any>(environment.corePathV2 + "user/user_locations/log", params).pipe(
      map(res => res || []),
      catchError(error => throwError(error))
    );
  }
  validateExistingUser(mobile: string, calling_code = '', userCountry_id) {
    let httpOptions = {}
		httpOptions = {
		  headers: new HttpHeaders({
			'X-Country-Id': userCountry_id
		  })
		}

    if (calling_code == '') {
      return this.http.post<any>(environment.corePathV2 + 'users/search', { mobile }, httpOptions)
        .pipe(
          map(res => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
    }
    else {
      return this.http.post<any>(environment.corePathV2 + 'users/search', { mobile, calling_code }, httpOptions)
        .pipe(
          map(res => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
    }
  }
  requestUserOtp(mobile: string, userId: string) {
    return this.http.post<any>(environment.corePathV2 + 'users/' + userId + '/otps', { mobile: mobile, otp_type: "login" })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }
  validateUserOtp(userId: string, otp: string, otpType: string, mobileNum?: any) {
    return this.http.post<any>(environment.corePathV2 + 'users/' + userId + '/otps/verify', { otp_type: otpType, code: otp })
      .pipe(
        map(res => {
          var user = {
            id: userId,
            token: res.token
          }
          if (mobileNum) {
            user['mobile'] = mobileNum;
          }
          if (user && user.token) {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(res);
          }
          return user;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }
  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.userLoggedIn$.next(false);
    this.router.navigate(['/']);
  }

  isAuthenticated() {
      return localStorage.getItem('currentUser') ? true : false;
  }
  addUserCard(bin) {
    return this.http.post(environment.corePathV2 + 'user/user_cards', { bin: bin, bin_type: 'first8' });
  }

  deleteToken() {
    if (JSON.parse(localStorage.getItem('login_details'))) {
      const currentUserId = JSON.parse(localStorage.getItem('login_details')).id;
      return this.http.post(environment.corePathV2 + 'sessions/' + currentUserId, {}).toPromise();
    }
  }
  createUser(mobile: string, email: string, calling_code: string = "default", userCountry_id) {
    let httpOptions = {}
		httpOptions = {
		  headers: new HttpHeaders({
			'X-Country-Id': userCountry_id
		  })
		}
    if (calling_code == 'default') {
      return this.http.post<any>(environment.corePathV2 + 'users', { mobile: mobile, email: email }, httpOptions)
        .pipe(
          map(res => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
    }
    else {

      return this.http.post<any>(environment.corePathV2 + 'users', { mobile: mobile, email: email, calling_code }, httpOptions)
        .pipe(
          map(res => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
    }

  }

  switchCard(card_id) {
		return this.http.post<any>(environment.corePathV2 + `/user_card/switch`, {card_id: card_id}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

}
