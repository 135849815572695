import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptorService } from './core/services/auth-interceptor/auth-interceptor.service';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
// ngrx related imports
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { CardsEffects } from './store/effects/cards.effects';
import { ErrorInterceptor } from './core/services/error-interceptor.service';
import { GlobalConstants } from './core/services/common/global-variables.service';
import { PV_USER_SERVICE } from '@pv-frontend/pv-shared-services/pv-user-service';
import { PvUserServiceImpl } from './user-service-impl.service';
import { PvAuthenticationModule } from '@pv-frontend/authentication';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { JOURNEY_METADATA_SERVICE_TOKEN } from '@pv-frontend/pv-shared-services/journey-metadata-service';
import { JourneyMetaDataServiceImpl } from './journey-metadata-impl.service';

import { Router } from "@angular/router";
 import * as Sentry from "@sentry/angular-ivy";

@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // // ngrx related imports
    StoreModule.forRoot(reducers, { metaReducers }),
    //
    // StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([ CardsEffects]),
    ToastrModule.forRoot({
      closeButton: true,
      preventDuplicates: true,
    }),
    PvAuthenticationModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      GlobalConstants,
      {
        provide: JOURNEY_METADATA_SERVICE_TOKEN,
        useExisting: JourneyMetaDataServiceImpl,
      },
      {
        provide: PV_USER_SERVICE,
        useExisting: PvUserServiceImpl,
      },
      {
        provide: 'environment',
        useValue: environment,
      },
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      }, {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      JourneyMetaDataServiceImpl,
      PvUserServiceImpl,
      MessageService,
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
