import {
  Country,
  UserCard,
  UserDetails,
} from '@pv-frontend/pv-shared-services/common';
import { PvUserService } from '@pv-frontend/pv-shared-services/pv-user-service';

interface CurrentState {
  token: string;
  isExpired: boolean;
  selectedCard: UserCard;
}
export class PvUserServiceImpl extends PvUserService {

  private tempUserToken: string = '';

  // COMMENT: had to implement as making this an abtract class would give us "Cannot assign an abstract constructor type to a non-abstract constructor type.""
  // COMMENT: had to disable lint for the below unImplemented functions
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public override setSelectedUserCardId(userCardId: string): void {
    localStorage.setItem('uc', JSON.stringify(userCardId));
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public override setCountries(countries: Country[]): void {
    throw new Error('Method not implemented.');
  }
  public override getCountries(): Country[] {
    throw new Error('Method not implemented.');
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public override getCountryById(countryId: string): Country | null {
    throw new Error('Method not implemented.');
  }
  //

  private getCurrentState(): CurrentState | null {

    return JSON.parse(localStorage.getItem('currentUser') || 'null');
  }

  public override setUserCards(userCards: UserCard[]): void {
    localStorage.setItem('userCards', JSON.stringify(userCards));
  }

  public override getUserCards(): UserCard[] {
    return JSON.parse(localStorage.getItem('userCards') ?? '[]');
  }

  public override setSelectedUserCard(userCard: UserCard | null): void {
    localStorage.setItem('userCard', JSON.stringify(userCard));
    this.setSelectedUserCardId(userCard.id);
    localStorage.setItem('card_verified', 'true');
  }

  public override getSelectedUserCard(): UserCard | null {
    return JSON.parse(localStorage.getItem('userCard'));
  }

  public override getSelectedUserCardId(): string {
    return this.getSelectedUserCard()?.id ?? '';
  }

  public override setToken(token: string): void {
    localStorage.setItem(
      'currentUser',
      JSON.stringify({
        token: token,
      })
    );
  }

  public override getToken(): string {
    return this.getCurrentState()?.token ?? '';
  }

  public override getUserDetails(): UserDetails | null {
    return JSON.parse(localStorage.getItem('userDetails') ?? 'null');
  }

  public override isUserAuthenticated(): boolean {
    return !!this.getToken();
  }

  public override setUserDetails(userDetails: UserDetails): void {
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
  }

  public override logoutUser(): void {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userCards');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('uc');
    localStorage.removeItem('card_verified');
  }

  public override setUserPoints(points: number): void {
    sessionStorage.setItem('pvUserPoints', points.toString());
  }

  public override getUserPoints(): number {
    return JSON.parse(sessionStorage.getItem('pvUserPoints') ?? '0');
  }

  public override isTokenExpired(): boolean {
    throw new Error('Method not implemented.');
  }

  public override setTempToken(tempToken: string): void {
    this.tempUserToken = tempToken;
  }
  public override getTempToken(): string | null {
    return this.tempUserToken;
  }
}
