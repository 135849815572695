import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [


  {
    path: '',
    loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule),
    // canActivate: [AuthGuard], // visit home only if authenticated
  },
  {
    path: 'spa-vouchers',
    loadChildren: () => import("./pages/spa-vouchers/spa-vouchers.module").then(m => m.SpaVouchersModule),
    // canActivate: [AuthGuard], // visit home only if authenticated
  },
  {
    path: 'sleeping-pod',
    loadChildren: () => import("./pages/sleeping-pod/sleeping-pod.module").then(m => m.SleepingPodModule),
    // canActivate: [AuthGuard], // visit home only if authenticated
  },
  {
    path: 'transit-hotel',
    loadChildren: () => import("./pages/hotel-offers/hotel-offers.module").then(m => m.HotelOffersModule),
    // canActivate: [AuthGuard], // visit home only if authenticated
  },
  {
    path: 'vip-services',
    loadChildren: () => import("./pages/vip-services/vip-services.module").then(m => m.VipServicesModule),
    // canActivate: [AuthGuard], // visit home only if authenticated
  },
  {
    path: "giftcards",
    loadChildren: () => import("./pages/gift-cards/booking-confirmed/booking-confirmed.module").then(m => m.BookingConfirmedModule)
  },

  {
    path: 'redemption-history',
    loadChildren: () => import("./pages/redemption/redemption-history.module").then(m => m.RedemptionHistoryModule)
  },
  {
    path: 'airport-concierge',
    loadChildren: () => import("./pages/vip-services/vip-services.module").then(m => m.VipServicesModule),
  },
  // {
  //   path: "giftcards",
  //   loadChildren: () => import("./pages/gift-cards/gift-cards.module").then(m => m.GiftCardsModule)
  // },
  // {
  //   path: "hotels",
  //   loadChildren: () => import("./pages/hotels/hotels.module").then(m => m.HotelsModule)
  // },
  // {
  //   path: 'flights',
  //   loadChildren: () => import("./pages/flights/flights.module").then(m => m.FlightsModule)
  // },
  // {
  //   path: 'charitable-donations',
  //   loadChildren: () => import("./pages/philanthrophy/philanthrophy.module").then(m => m.PhilanthrophyModule)
  // },
  // {
  //   path: 'redemption-history',
  //   loadChildren: () => import("./pages/redemption/redemption.module").then(m => m.RedemptionModule)
  // },
  // {
  //   path: 'cashback',
  //   loadChildren: () => import("./pages/cashback/cashback.module").then(m => m.CashbackModule)
  // },
  // { path: "error", component: Error500Component },
  {
    path: 'info',
    loadChildren: () => import("./pages/info/info.module").then(m => m.InfoModule)
  },
  // {
  //   path: "nat-geo",
  //   loadChildren: () => import("./pages/nat-geo/nat-geo.module").then(m => m.NatGeoModule)
  // },
  // {
  //   path: "golf",
  //   loadChildren: () => import("./pages/golf/golf.module").then(m => m.GolfModule)
  // },
  // {
  //   path: "golf-enquiry",
  //   loadChildren: () => import("./pages/golf/golf.module").then(m => m.GolfModule)
  // },
  // {
  //   path: "booking",
  //   loadChildren: () =>
  //     import("./pages/booking-history/booking-history.module").then(
  //       m => m.BookingHistoryModule
  //     )
  // },
  // {
  //   path: "transactions",
  //   loadChildren: () => import("./pages/transactions/transactions.module").then(m => m.TransactionsModule)
  // },
  // {
  //   path: "booking-failed",
  //   loadChildren: () => import("./pages/booking-failed/booking-failed.module").then(m => m.BookingFailedModule)
  // },
  // {
  //   path: 'sso_login',
  //   loadChildren: () => import("./pages/loginSso/loginSso.module").then(m => m.LoginSsoModule)
  // },
  // { path: "**", pathMatch: "full", loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule)}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
