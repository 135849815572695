interface CardGroupAttributes {
  id: string;
  name: string;
  brand: string;
  issuer: string;
  card_type: string;
  level: string;
}

interface CardAttributes {
    id: string;
    user_id: string;
    bin: string;
    bin_type: string;
    points: number;
    status: CARD_STATUSES;
    issuer: string;
    segment: string;
    card_type: string;
    display_name: string;
    user_name: string | null;
    user_email: string | null;
    point_redemption_allowed: boolean;
    country_id: string;
    session_token: string;
    credits: number;
    brand: string;
    card_detail: {
      name: string;
      segment_name: string;
    };
}

interface IncludedItemAttributes {
  id: string;
  type: string;
  attributes: CardGroupAttributes;
}

export interface CardApiResponse {
  data: {
    id: string;
    type: string;
    attributes: CardAttributes;
    relationships: {
      card_group: {
        data: {
          id: string;
          type: string;
        };
      };
    };
  };
  included: IncludedItemAttributes[];
}

export const CardStatuses = {
  bin_verified: 'bin_verified',
  payment_verified: 'payment_verified'
};

export type CARD_STATUSES = keyof typeof CardStatuses;