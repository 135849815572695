import { ChangeDetectorRef, EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

declare var Razorpay: any;
@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  apiUrl = environment.apiUrlPaV1;
  apiUrlLsV1 = environment.apiUrlLsV1;
  paymentModalClosed: boolean = false;
  paymentModalClosedEvent: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  paymentClickedEvent: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  constructor(
    private http: HttpClient,
    private toastr: ToastrService
  ) {
    this.ondismissCallback = this.ondismissCallback.bind(this);
    this.paymentModalClosedEvent.subscribe((isClosed: any) => {
      this.paymentModalClosed = isClosed;
    })
  }

  createPayment(data, discounted_project_id = false, pennyDropProjectId?: string) {
    let httpOptions = {}
    if(pennyDropProjectId) {
      httpOptions = {
	      headers: new HttpHeaders({
	        'X-Project-Id': pennyDropProjectId
	      })
	    }
    }
    if(discounted_project_id){
			httpOptions = {
	      headers: new HttpHeaders({
	        'discounted_project_id': 'true'
	      })
	    }
    }
    return this.http
      .post(this.apiUrl + 'payments', data, httpOptions)
      .toPromise();
  }



  getCardDetails(number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-API-Client': 'test_client',
        'X-API-Client-Token': '12345',
      })
    }
    // let url = environment.rewardsHubApiUrl + 'payments/get_card_details?card_num=' + number;
    // return this.http.get(url, httpOptions)
    let url = this.apiUrl + 'payments/get_card_details';
    return this.http.post(url, { card_num: number }, httpOptions)
  }

  getPaymentDetails(bookingId) {
    return this.http
      .get(this.apiUrl + 'payments/' + bookingId)
      .toPromise();
  }

  saveUserCard(data) {
    return this.http
      .post(this.apiUrl + 'payments/save_user_card', data)
      .toPromise();
  }

  getUserCards() {
    return this.http
      .post(this.apiUrl + 'payments/get_user_cards', {})
      .toPromise();
  }

  changePaymentStatus(booking_id) {
		return this.http.post(environment.apiUrlPaV1 + 'payments/change_status' ,{booking_id: booking_id, status: "user_cancelled"}).toPromise();
	}

  initiatePayment(bookingDetails, pennyDropProjectId?: string) {
    this.paymentClickedEvent.emit(true);
    let paymentDetails: any = {};
    paymentDetails = {
      ...bookingDetails,
      transactions_attributes: [{
        'txn_type': 'payment_gateway',
        'amount': bookingDetails?.amount,
      }]
    };
    if(this.paymentModalClosed) {
      this.reInitiatePayment(paymentDetails, pennyDropProjectId);
    } else {
      this.makePayment(paymentDetails, pennyDropProjectId);
    }
  }

  ondismissCallback(paymentDetails) {
    this.paymentModalClosedEvent.emit(true);
    this.paymentClickedEvent.emit(false);
    try {
      this.changePaymentStatus(paymentDetails.booking_id).then((response: any) => {}); 
    } catch (error) {
      console.log(error);
    }
  }

  reInitiatePayment(paymentDetails, pennyDropProjectId?: string) {
    this.paymentClickedEvent.emit(true);
    this.changePaymentStatus(paymentDetails.booking_id).then((response: any) => {
      if(response.status == "success" || response.status == "user_cancelled") {
        this.paymentModalClosedEvent.emit(false);
        this.makePayment(paymentDetails, pennyDropProjectId);
      } else {
        this.paymentClickedEvent.emit(false);
      }
    }, error => {
      this.paymentClickedEvent.emit(false);
    })
  }

  makePayment(paymentDetails: any, pennyDropProjectId?: string) {
    let pg_config = {
      display: {
        blocks: {
          banks: {
            name: 'Pay via Card',
            instruments: [
              {
                method: 'card',
                networks: ["Visa"]
              }
            ],
          },
        },
        sequence: ['block.banks'],
        preferences: {
          show_default_blocks: false,
        },
      },
    }
    this.createPayment(paymentDetails, false, pennyDropProjectId).then((payment: any) => {
      if (payment.status == 'error') {
        this.toastr.error(payment.msg)
        this.paymentClickedEvent.emit(false);
        return
      }
      payment["modal"] = {
        ondismiss: () => {this.ondismissCallback(paymentDetails)},
        animation: true
      };
      payment["theme"] = {
        color: "#2A86F3",
        backdrop_color: "#111218e6"
      };
      payment["config"] = pg_config
      let rzp1 = new Razorpay(payment);
      rzp1.open();
    });

  }

}
