<app-sidebar [cards] = "userCards" [selectedCard] = "selectedCard" [sidebarOpen] = "sidebarOpen" (emiLsidebarClose) = "sidebarOpen = false"></app-sidebar>
<header class="hdr pad-top-15 pad-bot-15" >
  <div class="d-flex justify-content-between align-items-center container main-container" >
    <div class="col-sm-4 menu-block">
      <span (click)="sidebarOpen = true" class="icon icon-hamburger-1 pointer fnt-26 icon-color"></span>
    </div>
    <div class="col-sm-4 logo-block flex justify-center">
      <a routerLink="/" class="mar-left-0" [class.lg]="'mar-left-8'" >
        <img class="logo" src="https://images.poshvine.com/collinson/Access+India_RGB_Digital+.svg" alt="logo">
      </a>
    </div>
    <div dropdown class="col-sm-4 card-block flex justify-content-end dropdown">
      <div class="d-flex justify-content-start align-items-center login-btn " >

        <div *ngIf = "!isUserLoggedIn" (click) = "openLoginModal()" class="d-flex flex-column justify-content-between align-items-start login-txt pointer">
          <button class="primary-btn mt-3" (click)="openLoginModal()">Login</button>
        </div>
        <ng-container *ngIf="isUserLoggedIn">
          <div  class="pointer" dropdown #dropdown="bs-dropdown">
            <div dropdownToggle  *ngIf = "isUserLoggedIn"  class="d-flex justify-content-start align-items-center login-btn " >
  
              <span class="icon-bg  mar-right-10 ">
                <span class="icon icon-card-2 fnt-26 icon-color"></span>
              </span>
              <div  class="card-block"   >
                <div class="card-num">{{selectedCard?.attributes?.bin | slice:0:4 }} {{selectedCard?.attributes?.bin | slice:-4 }} XXXX XXXX</div>
  
                <div class="fnt-12 card-name" [innerHtml] = "selectedCard?.attributes?.display_name"></div>
              </div>
            </div>
  
            <!-- <ng-container  *ngIf = "isUserLoggedIn"> -->
              <div *dropdownMenu class="dropdown-menu w100 profile-menu" (click)="dropdown.hide()">
                <ng-container *ngFor="let card of userCards" >
                  <div class="menu-single-item" *ngIf="card.attributes && card.attributes.status == CardStatuses.bin_verified ||  CardStatuses.payment_verified"
                    [ngStyle]="{'background-color': card.currentCard ? '#F0EBD9' : '#fff'}">
                    <div class="added-card-info d-flex justify-content-start align-items-center pointer" (click)="validateCard(card)">
                      <div>
                        <svg  class="d-none d-sm-block" width="54" height="46" viewBox="0 0 54 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="54" height="46" rx="3.42712" fill="#7F7133" fill-opacity="0.11"/>
                          <path d="M40.5 21.1936V32.209C40.5 32.5011 40.3536 32.7813 40.093 32.9879C39.8324 33.1944 39.4789 33.3105 39.1103 33.3105H14.0956C13.7271 33.3105 13.3736 33.1944 13.113 32.9879C12.8523 32.7813 12.7059 32.5011 12.7059 32.209V21.1936H40.5ZM40.5 18.9906H12.7059V14.5844C12.7059 14.2923 12.8523 14.0121 13.113 13.8055C13.3736 13.599 13.7271 13.4829 14.0956 13.4829H39.1103C39.4789 13.4829 39.8324 13.599 40.093 13.8055C40.3536 14.0121 40.5 14.2923 40.5 14.5844V18.9906ZM30.7721 27.8028V30.0059H36.3309V27.8028H30.7721Z" fill="#7F7133"/>
                        </svg>
  
                        <svg class="d-block d-sm-none" width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="24" height="20.4" rx="0.870475" fill="#7F7133" fill-opacity="0.11"/>
                          <path d="M18.2758 9.39903V14.2841C18.2758 14.4136 18.2107 14.5379 18.0949 14.6295C17.9791 14.7211 17.822 14.7726 17.6581 14.7726H6.5405C6.37669 14.7726 6.21959 14.7211 6.10376 14.6295C5.98792 14.5379 5.92285 14.4136 5.92285 14.2841V9.39903H18.2758ZM18.2758 8.42202H5.92285V6.468C5.92285 6.33844 5.98792 6.21418 6.10376 6.12257C6.21959 6.03096 6.37669 5.97949 6.5405 5.97949H17.6581C17.822 5.97949 17.9791 6.03096 18.0949 6.12257C18.2107 6.21418 18.2758 6.33844 18.2758 6.468V8.42202ZM13.9523 12.3301V13.3071H16.4229V12.3301H13.9523Z" fill="#7F7133"/>
                          </svg>
  
                      </div>
                      <div class="mar-left-12">
                        <div class="visa-drop-num">
                          {{card?.attributes?.bin | slice:0:4 }} {{card?.attributes?.bin | slice:-4 }} XXXX XXXX</div>
                        <div class="fnt-12 card-name mar-top-4"> {{card?.attributes?.display_name}}</div>
                      </div>
                    </div>
                  </div>
                </ng-container>
  
                <div class="menu-single-item" [routerLink]="'/redemption-history/food-vouchers'">
                  <div class="added-card-info d-flex justify-content-start align-items-center pointer">
                    <div>
                      <svg class="d-none d-sm-block" width="54" height="46" viewBox="0 0 54 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="54" height="46" rx="3.42712" fill="#7F7133" fill-opacity="0.11"/>
                        <g clip-path="url(#clip0_2858_60)">
                        <path d="M26.99 13C21.47 13 17 17.48 17 23C17 28.52 21.47 33 26.99 33C32.52 33 37 28.52 37 23C37 17.48 32.52 13 26.99 13ZM30.22 28.39L27 26.45L23.78 28.39C23.4 28.62 22.93 28.28 23.03 27.85L23.88 24.19L21.05 21.74C20.72 21.45 20.9 20.9 21.34 20.86L25.08 20.54L26.54 17.09C26.71 16.68 27.29 16.68 27.46 17.09L28.92 20.53L32.66 20.85C33.1 20.89 33.28 21.44 32.94 21.73L30.11 24.18L30.96 27.85C31.06 28.28 30.6 28.62 30.22 28.39Z" fill="#7F7133"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_2858_60">
                        <rect width="24" height="24" fill="white" transform="translate(15 11)"/>
                        </clipPath>
                        </defs>
                      </svg>
  
                      <svg class="d-block d-sm-none" width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.200195" width="24" height="20.4" rx="0.870475" fill="#7F7133" fill-opacity="0.11"/>
                        <g clip-path="url(#clip0_2864_89)">
                        <path d="M11.995 5C9.235 5 7 7.24 7 10C7 12.76 9.235 15 11.995 15C14.76 15 17 12.76 17 10C17 7.24 14.76 5 11.995 5ZM13.61 12.695L12 11.725L10.39 12.695C10.2 12.81 9.965 12.64 10.015 12.425L10.44 10.595L9.025 9.37C8.86 9.225 8.95 8.95 9.17 8.93L11.04 8.77L11.77 7.045C11.855 6.84 12.145 6.84 12.23 7.045L12.96 8.765L14.83 8.925C15.05 8.945 15.14 9.22 14.97 9.365L13.555 10.59L13.98 12.425C14.03 12.64 13.8 12.81 13.61 12.695Z" fill="#7F7133"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_2864_89">
                        <rect width="12" height="12" fill="white" transform="translate(6 4)"/>
                        </clipPath>
                        </defs>
                      </svg>
  
                    </div>
                    <div class="mar-left-12">
                      <span class="visa-drop-num">Redemption History</span>
                    </div>
                  </div>
                </div>
  
                <div class="menu-single-item" (click)="logout()">
                  <div  class="added-card-info d-flex justify-content-start align-items-center pointer">
                    <div>
                      <svg class="d-none d-sm-block" width="54" height="46" viewBox="0 0 54 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="54" height="46" rx="3.42712" fill="#7F7133" fill-opacity="0.11"/>
                        <g clip-path="url(#clip0_2858_67)">
                        <path d="M20 16H26C26.55 16 27 15.55 27 15C27 14.45 26.55 14 26 14H20C18.9 14 18 14.9 18 16V30C18 31.1 18.9 32 20 32H26C26.55 32 27 31.55 27 31C27 30.45 26.55 30 26 30H20V16Z" fill="#7F7133"/>
                        <path d="M35.65 22.65L32.86 19.86C32.54 19.54 32 19.76 32 20.21V22H25C24.45 22 24 22.45 24 23C24 23.55 24.45 24 25 24H32V25.79C32 26.24 32.54 26.46 32.85 26.14L35.64 23.35C35.84 23.16 35.84 22.84 35.65 22.65Z" fill="#7F7133"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_2858_67">
                        <rect width="24" height="24" fill="white" transform="translate(15 11)"/>
                        </clipPath>
                        </defs>
                      </svg>
  
                      <svg class="d-block d-sm-none" width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.600098" width="24" height="20.4" rx="0.870475" fill="#7F7133" fill-opacity="0.11"/>
                        <g clip-path="url(#clip0_2864_106)">
                        <path d="M8.5 6.50049H11.5C11.775 6.50049 12 6.27549 12 6.00049C12 5.72549 11.775 5.50049 11.5 5.50049H8.5C7.95 5.50049 7.5 5.95049 7.5 6.50049V13.5005C7.5 14.0505 7.95 14.5005 8.5 14.5005H11.5C11.775 14.5005 12 14.2755 12 14.0005C12 13.7255 11.775 13.5005 11.5 13.5005H8.5V6.50049Z" fill="#7F7133"/>
                        <path d="M16.325 9.82526L14.93 8.43026C14.77 8.27026 14.5 8.38026 14.5 8.60526V9.50026H11C10.725 9.50026 10.5 9.72526 10.5 10.0003C10.5 10.2753 10.725 10.5003 11 10.5003H14.5V11.3953C14.5 11.6203 14.77 11.7303 14.925 11.5703L16.32 10.1753C16.42 10.0803 16.42 9.92026 16.325 9.82526Z" fill="#7F7133"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_2864_106">
                        <rect width="12" height="12" fill="white" transform="translate(6 4.00049)"/>
                        </clipPath>
                        </defs>
                      </svg>
  
                    </div>
                    <div class="mar-left-12">
                      <span class="visa-drop-num">Logout</span>
                    </div>
                  </div>
                </div>
              </div>
            <!-- </ng-container> -->
          </div>
        </ng-container>

      </div>
    </div>
  </div>

</header>
