<section class="d-flex flex-column payment-verification-container align-items-start px-4 py-5">
    <ng-container *ngIf="!isPaymentVerificationConfirmed; else initiatePennyDrop">
        
        <img src="https://images.poshvine.com/icon/reward_shield.svg" class="reward_shield" alt="reward_shield" />
        <h5 class="mt-3">Initiating card verification</h5>
        <p class="text-lg mt-3">
            To verify your bank account, complete a test payment of Rs 1 on your card. The amount will be refunded after successful verification
        </p>

        <button class="primary-btn mt-auto" (click)="isPaymentVerificationConfirmed = true">Continue</button>
    </ng-container>
    <ng-template #initiatePennyDrop>

        <h5>
            Enter card details
        </h5>

        <form [formGroup]="paymentForm" class="h-100">
            <div class="d-flex flex-column h-100 py-5">

                <label class="surface-text-subtle-low-contrast text-sm mb-2">Card Number</label>

                <pv-bin-input
                    class="flex justify-content-center w-full"
                    variant="last8"
                    separator="_"
                    [style]="binInputStyle"
                    [isInvalid]="isBinInvalid"
                    errorMessage=""
                    formControlName="binControll">
                </pv-bin-input>

                <button class="primary-btn mt-auto mr-auto" [ngClass]="{'disabled': isBinIncorrect || isPaymentInProgress}" [disabled]="isBinIncorrect || isPaymentInProgress" (click)="initiatePennyDropPayment()">Continue</button>
            </div>
        </form>
    </ng-template>

    
</section>
<div class="feedback-background-positive-low-contrast px-5 py-3 flex align-items-center pci-block mt-auto mx-0">
    <img
    src="https://images.poshvine.com/logos/pci-logo.png"
    class="mr-2"
    [style]="binInputStyle?.pciImage"
    alt="PCI DSS" />
    <span class="text-xs surface-text-subtle-low-contrast" [style]="binInputStyle?.pciLabel"> 
        Your card / Account parameters are used only to check eligibility and are encrypted
    </span>
</div>