import { createReducer, on } from '@ngrx/store';
import * as cardActions from '../actions/cards.action';

export interface CardsState {
  cards: any;
}
export const initialState: any = {};

export const cardsReducer = createReducer(
  initialState,
  on(cardActions.cardsSuccess, (state, result) => ({cards: result})),
  // on(cardActions.cardsFailure, (state, {result}) => [result])
  // on(countryActions.countriesSuccess, (state, {countries}) => [...countries])
);
