import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { PV_USER_SERVICE, PvUserService } from '@pv-frontend/pv-shared-services/pv-user-service';
import { finalize } from 'rxjs';
import { CardStatuses, CardApiResponse } from 'src/app/interfaces/auth.types';
import { UserCard } from '@pv-frontend/pv-shared-services/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-verification-after-payment',
  templateUrl: './card-verification-after-payment.component.html',
  styleUrls: ['./card-verification-after-payment.component.scss']
})
export class CardVerificationAfterPaymentComponent {

  public isValidatingCard: boolean = true;
  public isPaymentVerified: boolean = false;
  public CardStatuses = CardStatuses;
  
  @Output() closeModal = new EventEmitter();

  constructor(
    private authService: AuthService,
    @Inject(PV_USER_SERVICE) public pvUserService: PvUserService,
    private router: Router
  ) {
    this.getLatestSelectedCardDetails();
  }

  private getLatestSelectedCardDetails(): void {
    const bin = this.pvUserService.getSelectedUserCard()?.attributes?.bin;
    this.authService.addUserCard(bin)
      .pipe(finalize(() => this.isValidatingCard = false))
      .subscribe({
        next: (response: CardApiResponse) => {
          this.pvUserService.setSelectedUserCard(response.data as UserCard);
          this.isPaymentVerified = response.data.attributes.status === CardStatuses.payment_verified;
          if(this.isPaymentVerified) {
            setTimeout(() => {
              this.router.navigate(['/']);
              this.closeModal.emit();
            }, 2000)
          } else {
            this.pvUserService.notifyUserLogin(true);
          }
        }
      })
  }

}
