import { Component, DestroyRef, Inject, inject } from '@angular/core';
import { PV_USER_SERVICE, PvUserService } from '@pv-frontend/pv-shared-services/pv-user-service';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AuthService } from './core/services/auth/auth.service';
import { finalize } from 'rxjs';
import { CardApiResponse } from './interfaces/auth.types';
import { UserCard } from '@pv-frontend/pv-shared-services/common';
import { ApiService } from './shared/services/api/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  private readonly destroy$: DestroyRef = inject(DestroyRef);
  public showAuthenticationModal: boolean = false;
  public isValidatingCard: boolean = false;
  
  constructor(@Inject(PV_USER_SERVICE) public pvUserService: PvUserService, private authService: AuthService, private apiService: ApiService) {
    this.pvUserService.loginModalObservable$
      .pipe(takeUntilDestroyed(this.destroy$))
      .subscribe((showAuthenticationModal: boolean) => {
        this.showAuthenticationModal = showAuthenticationModal;
      });

    this.pvUserService.userLoggedInObservable$
      .pipe(takeUntilDestroyed(this.destroy$))
      .subscribe((isLoggedIn: boolean) => {
        if(isLoggedIn) {
          this.isValidatingCard = true;

          // Currently hardcoding it to MeetAndAssistEntitlement for demo purpose
          this.apiService.getEntitlementEligibility('MeetAndAssistEntitlement')
          .pipe(takeUntilDestroyed(this.destroy$))
          .subscribe((response: any) => {
            if(response.status === 'success') {
              localStorage.setItem("isEligibleForFreeEntitlements", 'true');
              this.getCardBasedToken();
            } else {
              this.isValidatingCard = false;
              this.authService.userLoggedIn$.next(true);
            }
          });
        }
      });
  }

  private getCardBasedToken(): void {
    this.authService.switchCard(this.pvUserService.getSelectedUserCardId())
      .pipe(
        takeUntilDestroyed(this.destroy$),
        finalize(() => this.isValidatingCard = false)
      )
      .subscribe({
        next: (response: CardApiResponse) => {
          const token = response.data.attributes.session_token;
          if(token) {
            this.pvUserService.setToken(token);
            this.pvUserService.setSelectedUserCard(response.data as UserCard);
            this.authService.userLoggedIn$.next(true);
          }
        }
      })
  }

}
