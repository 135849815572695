import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import * as cardActions from '../actions/cards.action';

@Injectable()

export class CardsEffects{
  constructor(
    private actions$: Actions,
    private _authService: AuthService
  ){}

  loadCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cardActions.getCards),
      // exhaustMap((a) =>
      switchMap((action) =>
        this._authService.getCards(action.params).pipe(
          map(response => cardActions.cardsSuccess(response)),
          catchError((error: any) => of(cardActions.cardsFailure(error))))
      )
    ),{dispatch: true}
  );
}
