import { ActionReducer, ActionReducerMap, MetaReducer, INIT } from '@ngrx/store';
// import {countriesReducer, CountriesState } from './reducers/countries.reducer';
import {cardsReducer, CardsState } from './reducers/cards.reducer';
// import {redemptionsReducer, RedemptionsState } from './reducers/redemptions.reducer';
// import {categoriesReducer, CategoriesState, categoriesDetailsReducer } from './reducers/categories.reducer';
// import {offersReducer, OffersState} from './reducers/offers.reducer';
import { logout } from './actions/countries.action';





export const reducers: ActionReducerMap<any> = {
  // countries: countriesReducer,
  cards: cardsReducer,
  // categories: categoriesReducer,
  // categoryDetails: categoriesDetailsReducer,
  // offers: offersReducer,
  // redemptions: redemptionsReducer
}
// export const allReducers: ActionReducerMap<any> = {
//   cards: cardsReducer,
//   countries: countriesReducer
// }

const debugMeta = (reducer: ActionReducer<any>): ActionReducer<any> => {
  return (state, action) => {
    // console.log('state', state);
    // console.log('action', action);

    return reducer(state, action);
  };
};

const logoutMeta = (reducer: ActionReducer<any>): ActionReducer<any> => {
  return (state, action) => {
    if(action?.type == logout.type){
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
};

export const metaReducers: MetaReducer<any>[] = [debugMeta];
