import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../../services/common/global-variables.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktopDevice: boolean = false;
  currentYear: number = new Date().getFullYear();

  constructor(public GlobalConstants: GlobalConstants) {
    this.onResize();
  }

  ngOnInit() {}
  onResize() {
    if (window.innerWidth > 1024) {
      this.isDesktopDevice = true;
      this.isTablet = false;
      this.isMobile = false;
    } else if (window.innerWidth > 992) {
      this.isDesktopDevice = false;
      this.isTablet = true;
      this.isMobile = false;
    } else {
      this.isDesktopDevice = false;
      this.isTablet = false;
      this.isMobile = true;
    }
  }
}
