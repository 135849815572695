import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { NgxDaterangepickerMd } from './components/daterangepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { BannerComponent } from './components/banner/banner.component';
import { GiftCardComponent } from './components/gift-card/gift-card.component';
import { NotEligibleModalComponent } from './components/not-eligible-modal/not-eligible-modal.component';
import { NotEligibleComponent } from './components/not-eligible/not-eligible.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { CardLoaderComponent } from './components/loader/card-loader/card-loader.component';
import { CartComponent } from 'src/app/pages/gift-cards/cart/cart.component';
import { StatusLabelComponent } from 'src/app/shared/components/status-label/status-label.component';


import { IndianCurrency } from './pipes/indian-currency.pipe';
import { CreditCardMaskPipe } from './pipes/credit-card-mask.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { CleanHtmlPipe } from './pipes/clean-html.pipe';
import { ImagePreloadDirective } from './directives/image-preload.directive';

import { TitleCasePipe } from '@angular/common';

import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { BackComponent } from './components/back/back.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SafeHtml } from './pipes/safe.pipe';
import { RemoveUnderscorePipe } from './pipes/remove-underscore.pipe';
import { TncComponent } from './components/tnc/tnc.component';
import { TerminalBarComponent } from './components/terminal-bar/terminal-bar.component';
import { ServiceCardComponent } from './components/service-card/service-card.component';
import { OutsideClickDirective } from './directives/click-outside.directive';
import { PaymentVerificationComponent } from './components/payment-verification/payment-verification.component';
import { PvBinInputModule } from '@pv-frontend/pv-shared-components/bin-input';
import { CardVerificationAfterPaymentComponent } from './components/card-verification-after-payment/card-verification-after-payment.component';
import { SvgIconModule } from '@pv-frontend/pv-shared-components/svg-icon';

@NgModule({
  declarations: [
    BannerComponent,
    GiftCardComponent,
    NotEligibleModalComponent,
    NotEligibleComponent,
    NoDataComponent,
    CardLoaderComponent,
    LoaderComponent,
    CartComponent,
    BackComponent,
    StatusLabelComponent,
    TncComponent,
    TerminalBarComponent,
    ServiceCardComponent,

    // Pipes
    IndianCurrency,
    CreditCardMaskPipe,
    FilterPipe,
    CleanHtmlPipe,
    SafeHtml,
    // IncrementDecrementPipe,
    RemoveUnderscorePipe,
    // Time24to12Pipe,
    // ReplacePipe,
    // MinuteSecondsPipe,

    //directives
    NumbersOnlyDirective,
    ImagePreloadDirective,
    OutsideClickDirective,
    PaymentVerificationComponent,
    CardVerificationAfterPaymentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PvBinInputModule,
    SvgIconModule
    // NgxDaterangepickerMd.forRoot({
    //   firstDay: 0,
    // }),
  ],
  exports: [
    CommonModule,
    RouterModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    PopoverModule,
    BsDatepickerModule,
    // NgxDaterangepickerMd,

    BannerComponent,
    GiftCardComponent,
    NotEligibleModalComponent,
    NotEligibleComponent,
    NoDataComponent,
    CardLoaderComponent,
    LoaderComponent,
    CartComponent,
    BackComponent,
    StatusLabelComponent,
    TncComponent,
    TerminalBarComponent,
    ServiceCardComponent,
    // Pipes
    IndianCurrency,
    CreditCardMaskPipe,
    FilterPipe,
    CleanHtmlPipe,
    SafeHtml,
    // IncrementDecrementPipe,
    RemoveUnderscorePipe,
    // Time24to12Pipe,
    // ReplacePipe,
    // MinuteSecondsPipe,

    NumbersOnlyDirective,
    ImagePreloadDirective,
    OutsideClickDirective
  ],
  providers: [
    TitleCasePipe
  ]
})
export class SharedModule { }
