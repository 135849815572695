import { Component, OnInit, HostListener, ElementRef, Inject, DestroyRef, inject } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {select} from '@ngrx/store';
import * as cardsActions from 'src/app/store/actions/cards.action';
import { DataTransferService } from '../../services/common/datatransfer.service';
import { CommonService } from '../../services/common/common.service';
import { GlobalConstants } from '../../services/common/global-variables.service';
import { PV_USER_SERVICE, PvUserService } from '@pv-frontend/pv-shared-services/pv-user-service';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PaymentVerificationComponent } from 'src/app/shared/components/payment-verification/payment-verification.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CardVerificationAfterPaymentComponent } from 'src/app/shared/components/card-verification-after-payment/card-verification-after-payment.component';
import { CardStatuses } from 'src/app/interfaces/auth.types';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  private readonly destroy$: DestroyRef = inject(DestroyRef);
  public isUserLoggedIn: boolean = this._authService.isAuthenticated();
  modalRef!: BsModalRef;
  public CardStatuses = CardStatuses;
  
  constructor(
    public _authService: AuthService,
    private readonly store: Store,
    private elementRef: ElementRef,
    private dataTransferService: DataTransferService,
    public _commonService: CommonService,
    public GlobalConstants: GlobalConstants,
    @Inject(PV_USER_SERVICE) public pvUserService: PvUserService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this._authService.userAuthenticatedObservable$
      .pipe(takeUntilDestroyed(this.destroy$))
      .subscribe((isLoggedIn: boolean) => {
        this.isUserLoggedIn = isLoggedIn;
        if(isLoggedIn) {
          this.getCards(false);
          const selectedCard = this.pvUserService.getSelectedUserCard();
          const isEligibleForFreeEntitlements = JSON.parse(localStorage.getItem("isEligibleForFreeEntitlements") || 'false');
          if(selectedCard.attributes.status !== CardStatuses.payment_verified && isEligibleForFreeEntitlements) {
            this.openPaymentVerificationModal();
          }
        }
      });
      this.route.queryParams.subscribe(params => {
        // COMMENT: paymenVerified param to check if bin status has changed to 'payment-verified'.
        if(params[CardStatuses.payment_verified]) {
          this.verifiyPayment();
        }
      });
  }

  refreshSubscription: Subscription;
  @HostListener('unloaded')
  ngOnDestroy() {
    // this.dataTransferService.nextMessage(null)
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe()
    }
    this.elementRef.nativeElement.remove();
  }
  ngOnInit(): void {
    this.getCards(false);
    this.checkRefresh();
  }
  checkRefresh() {

    if (!this.refreshSubscription) {
      this.refreshSubscription = this.dataTransferService.sharedRefreshEvent.subscribe(res => {

        if (res) {
          this.getCards(true)
        }
      });
    }
  }
  userCards;
  selectedCard;
  sidebarOpen: boolean = false;
  getCards(isReload){
    if (this._authService.isAuthenticated()) {
      this.store.pipe(select((state: any) => state.cards)).subscribe((res) => {
        if(res && res.cards && !isReload){
          // this.userCards = res.cards.data;
          this.userCards = res.cards.data.map((item) => ({
             ...item
            }));
          this.checkSelectedCard();
          isReload = false;
        }
        else{
          this.store.dispatch(cardsActions.getCards({}));
        }
        isReload = false;
      });
    }
  }
  checkSelectedCard(){
    if (localStorage.getItem('uc') && localStorage.getItem('uc') != 'undefined') {
      let selectedCardId = JSON.parse(localStorage.getItem('uc'));
      if(!this.userCards) {
        this.userCards = this.pvUserService.getUserCards();
      }
      this.userCards.forEach((x) => {
        if (selectedCardId && (x.id === selectedCardId)) {
          x['currentCard'] = true;
          this.selectedCard = x;
        } else {
          x['currentCard'] = false;
        }
      });
      this.userCards.sort(function(x,y){ return x.currentCard ? -1 : y.currentCard ? 1 : 0; });
    }
    else{
      this._authService.logout()
    }
  }
  openLoginModal() {
    if (!this._authService.isAuthenticated()) {
      this.pvUserService.toggleAuthModal(true);
    }
  }

  validateCard(card) {
    this._authService.switchCard(card.id).subscribe((res) => {
      if (
        res &&
        res.data &&
        res.data.attributes.bin == card.attributes.bin &&
        res.data.attributes.status == CardStatuses.bin_verified || CardStatuses.payment_verified
      ) {
        let current_user = JSON.parse(localStorage.getItem("currentUser"));
        current_user.token = res.data.attributes.session_token;
        this.pvUserService.setToken(current_user.token);
        localStorage.setItem("currentUser", JSON.stringify(current_user));
        this.pvUserService.setSelectedUserCard(res.data);
        this.pvUserService.setSelectedUserCardId

        // location.reload();
        this.selectedCard = this.pvUserService.getSelectedUserCard();
        if(this.selectedCard.attributes.status !== CardStatuses.payment_verified) {
          this.pvUserService.notifyUserLogin(true);
        } else {
          this._authService.userLoggedIn$.next(true);
        }
        this.checkSelectedCard();
        this.router.navigate(['/']);
        
      } else {
        localStorage.setItem("card_verified", "false");
      }
    });
  }

  logout() {
    this.pvUserService.notifyUserLogin(false);
    this._authService.logout();
  }

  private openPaymentVerificationModal() {
    var config = {
      backdrop: true,
      ignoreBackdropClick: false,
      class: 'login-modal'
    };
    this.modalRef = this.modalService.show(PaymentVerificationComponent, Object.assign({}, config));
   
    if (this.modalRef) {
      this.modalRef.content?.closeModal?.subscribe(() => {
        this.modalRef.hide();
        this.pvUserService.logoutUser();
        this.pvUserService.notifyUserLogin(false);
      });

      this.modalRef.onHide.subscribe(() => {
        console.log("is loggedOut")
        this._authService.logout();
      });
    }
  }

  private verifiyPayment(): void {
    var config = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'login-modal'
    };
    this.modalRef = this.modalService.show(CardVerificationAfterPaymentComponent, Object.assign({}, config));
    this.modalRef.content.closeModal.subscribe(() => {
      this.modalRef.hide();
    });
  }


}
