import { Inject, Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PV_USER_SERVICE, PvUserService } from '@pv-frontend/pv-shared-services/pv-user-service';

export type ValueOf<T> = T[keyof T];

export type binValueType = "Elite" | "Gold" | "Silver";

export const EntitlementCheckResponseStatuses = {
  ERROR: 'error',
  SUCCESS: 'success'
}

export interface EntitlementCheckSuccessResponseWithData {
  status: ValueOf<typeof EntitlementCheckResponseStatuses>;
  data: {
    eligibility_count: number;
    availed: number;
    pending: number;
  };
}

interface UserPack {
  name: string;
  email: string;
  mobile: string;
  available_at: string | null;
  special_request: string | null;
}

export interface ConciergeRequest {
  id: string;
  type: string;
  attributes: {
    booking_amount: null;
    user_pack: UserPack;
    form_data: string;
    status: string;
    created_at: string;
    updated_at: string;
    category: string;
    fare_breakup: {};
    booking_id: string;
  };
}

export interface FreeEntitlementBookingsResponse {
  data: ConciergeRequest[];
}

export interface FreeEntitlementBookingResponse {
  data: ConciergeRequest;
}

export interface EnquireyCreationResponse {
  status: string;
  data: {
    booking_id: string;
    concierge_request_id: string;
    status: string;
  }
}

@Injectable({
  providedIn: "root"
})
export class ApiService {

  constructor(private http: HttpClient, @Inject(PV_USER_SERVICE) public pvUserService: PvUserService,) { }

  get(endpoint, params?) {
    return this.http.get(endpoint, params);
  }

  post(endpoint, body, params?) {
    return this.http.post(endpoint, body, params);
  }

  delete(endpoint, params?) {
    return this.http.delete(endpoint, params);
  }
  getUserDetails() {
    return this.http.get<any>(environment.corePathV2 + '/user', {})
      .pipe(
        map(res => res || res),
        catchError(error => throwError(error))
      );
  }
  getAllCountries() {
    return this.http.get(environment.corePathV2 + "/countries").pipe(
      map(res => res || []),
      catchError(error => throwError(error))
    );
  }

  getCarterXRedirecitonLink(req: {mobile: string, country_code: string, email?: string}) {
    return this.http.post(' https://hyd.carterx.in/index.php?r=customer-api/poshvine-login', req).pipe(
      catchError(error => throwError(error))
    );
  }

  public getEntitlementEligibility(entitlementCode: string): Observable<EntitlementCheckSuccessResponseWithData> {

    return this.http.get<EntitlementCheckSuccessResponseWithData>(`${environment.apiUrlBsV1}concierge/check_bin`, {
      params: {
        bin: this.pvUserService.getSelectedUserCard()?.attributes?.bin?.slice(0,6),
        category: entitlementCode
      }
    });

    // COMMENT: below is for dev purpose as i was blocked with right bin to test with
    // return of<EntitlementCheckSuccessResponseWithData>({
    //   status: EntitlementCheckResponseStatuses.SUCCESS,
    //   data: {
    //     eligibility_count: 4,
    //     availed: 0,
    //     pending: 4,
    //   }
    // }).pipe(delay(2000))
  }

  public createEnquirey(payload: any): Observable<EnquireyCreationResponse> {
    return this.http.post<EnquireyCreationResponse>(`${environment.apiUrlBsV1}/concierge/enquiry`, payload)
  }

  public getFreeVipBookings(): Observable<FreeEntitlementBookingsResponse> {
    return this.http.get<FreeEntitlementBookingsResponse>(`${environment.apiUrlBsV1}concierge`);
  }

  public getFreeVipBookingById(bookingId: string): Observable<FreeEntitlementBookingResponse> {
    return this.http.get<FreeEntitlementBookingResponse>(`${environment.apiUrlBsV1}concierge/${bookingId}`);
  }

}
