import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GlobalConstants {


  client: any;
  data: any;
  // clientList = {
  //     visa: {
  //         projectId: 'a57d8f31-3a6d-4e67-94b9-27224b3ecfa5',
  //         merchantId: '89e0e2d6-7571-4f6f-9595-79301ecf6222'
  //     }
  // };

  constructor(@Inject(DOCUMENT) private document: any, private router: Router) {
    if (this.document.location.hostname.includes("visa")) {
      this.client = "visa";
    }

    switch (this.client) {
      case "visa":
          this.data = {
              "x_header": {
                  "prod": {
                    'project_id': '2e4ea933-9dbd-48cc-b114-cb69bcc738b0'
                  },
                  "sandbox": {
                      'project_id': 'e83c513f-e29a-46d3-b00d-3ef45f18f163'
                  },
              },
              "landing_page_banner": 'https://images.poshvine.com/collinson/Banner-landing-page-1.png',
              "discount_label": 'Visa Discount',
              "subtitle_text": "Use your Visa card to avail these services at discounted rates",
              "footer_text": 'Priority Pass Access India™ is a platform for communication of personalized offers extended by merchants to Visa Customers. Priority Pass Access India is only communicating the offers extended by merchants to its customers and not selling/rendering any of these products/services. Visa is neither guaranteeing nor making any representation.',
              "prelogin_text": 'Add your Visa card',
              "link": 'https://www.visa.prioritypassaccessindia.com'
          };
          break;

          this.data = {
              "logo": {
                  "url": "https://images.poshvine.com/logos/au-site-logo.png",
                  "footerurl": "https://images.poshvine.com/logos/AU-bank-Scheduled-commercial-bank-logo-white.png",
                  "desktop_url": "https://images.poshvine.com/logos/au-site-logo.png",
                  "modal_url": "https://images.poshvine.com/logos/au-modal-logo.png",
                  "favicon": "./assets/favicon/aufinance.ico",
                  "head_logo_height": "43",
                  "coupon_modal_logo": "https://images.poshvine.com/logos/au-site-logo.png"
              },
              "assets": {
                  "img": {},
                  "manifest": "au-bank.webmanifest"
              },
              "links": {
                  "projectLink": "https://offers.aubank.in/"
              },
              "colors": {
                  "header_bg_color": "#672474",
                  "coupon_modal_bg_color": "#672474",
                  "offer_title_color": '#672474',
                  "main_text_color": '#262626',
                  "medium_text_color": '#5C5C5C',
                  "light_text_color": '#9D9D9D',
                  "main_bg_color": '#ED6C37',
                  "active_card_bg_color": '#F4F4F4',
                  "seconday_bg_color": '#FDFCFA',
                  "mainBtn_color": '#ED6C37',
                  "inactive_btn_color": '#D8D8D8',
                  "btn_positive_action_color": '#06B08A',
                  "active_text_color": '#672474',
              },
              "legal_name": "AU Small Finance Bank",
              "font_family": {
                  "link": "https://images.poshvine.com/fonts/gotham-round/Gotham-Rounded.css",
                  "name": "Gotham-Rounded"
              },
              "x_header": {
                  "prod": {
                      'client_id': '3b1a1d77-0d4a-4edf-925d-230d45eaa37d',
                      'project_id_credit': '2fc98a98-b3d2-4d54-8564-a17620c33c5c',
                      'project_id_debit': '7ebeeb19-12fc-4f10-87a2-ac64e83d533e'
                  },
                  "sandbox": {
                      'client_id': 'c762195d-b49b-451e-b050-0aca4c36f770',
                      'project_id_credit': 'e3e60031-689c-499e-b627-da67db42c785',
                      'project_id_debit': '6ec49a81-f06e-4d9f-9350-9e9b6e8d3685',
                  },
                  "default_card_type": 'debit'
              },
              "search_type": 2,
              "desktop_category_count": 8,
              "card_num_type": "first4_last4",
              "card_type": "Debit",
              "tabs_type": "category",
              "tabs_type_mobile": "category",
              "has_exclusive": false,
              "banner_type": "slider",
              "login_button_needed": true,
              "loginAt": "otp",
              "login_theme": 1,
              "login_steps": ['mobile','otp','card'],
              "auto_expiry": {
                  "time": "15",
                  "enabled": true
              },
              "template": 1,
              "header_type": "au-bank",
              "show_saving_for_bundle_codes": {},
              "download_app_link": {
                  'header': false,
                  'footer': false,
                  'android_link': null,
                  'apple_link': null
              },
              "login_modal_type": "desktop-prev-header",
              "supported_devices": ['web', 'mobile'],
              "benefits": ['offers'],
              "banner_style": 1,
              "carouselInterval": -1,
              "api_version": 'v2',
              "credits_type": 'bundle',
              "token_attr": 'data',
              "personalized_config": {
                  "coupon_terms_link": false,
                  "switch_card": true,
                  "autocard_check": true,
                  "view_savings": true,
                  "external_otp": false,
                  "redemption_page_needed": true,
                  "redemption_terms_btn": true,
                  "saving_page": false,
                  "resend_allowed": true,
                  "add_new_card": false,
                  "footer_logo": false,
                  "force_auth": false,
                  "token_auth": false,
                  "desktop_search": true,
                  "mobile_search": true,
                  "auth": true,
                  "coupon_modal": true,
                  "coupon_show": true,
                  "amount_show": false,
                  "modal_redeem_button": true,
                  "mobile_home_back_button": true,
                  "show_card_used": true,
                  "show_bundle_used": false,
                  "allow_user_create": true,
                  "otp_indication_msg": true,
                  "enter_otp_line_2": true,
                  "smart_country_dropdown": true,
                  "dedicated_banner": true,
                  "mobile_banner": true,
                  "authenticate_before_claim": true,
                  "disclaimer_popup": true,
                  "cancelable_auth": true,
                  "display_bundle_category_title": true,
                  "mobile_login": true,
                  "is_concierge": false,
                  "has_card": true,
                  "home_subcategory_dropdown": true,
                  "home_subcategory_bar": true,
                  "has_near_by": true,
                  "has_international": true,
                  "mobile_profile_dropdown": true,
                  "category_page": true,
                  "authenticate_bin": true,
                  "datalayer": true,
                  "has_add_card_text": true,
                  "custom_gtm_tags": true,
                  "show_terms_before_redeem": false,
                  "details_desktop_tabs": true,
                  "show_special_category": true,
                  "send_project_id": false,
                  "show_card_applicability": true,
                  "card_applicability_fetch": 'api',
                  "show_card_select_on_auth": true,
                  "special_bundle": true,
                  "external_login_banner": false,
                  "has_international_offers": true,
                  "send_as_gift": false,
                  "applicableTag": true,
                  "has_maps": false
              },
              "titles": {
                  "homepage_pagetitle": "Card Offers, Discounts and Deals - AU Small Finance Bank",
                  "homepage_description": "Avail exciting card offers, deals and discounts on shopping, travel, health, wellness, etc. Check out all the various benefits offered by AU Bank Card. Click to know more.",
                  "homepage_keywords": "au bank offers, card offers, card discounts, card deals, card privileges, special offers, travel offers, entertainment offers, business offers, shopping offers, dining offers, health and wellness offers, offers near me",
                  "header_title": "",
                  "modal_mobile_label": "Enter your mobile number registered with AU Bank",
                  "modal_email_label": "Enter your email address registered with AU Bank",
                  "card_name": "AU Small Finance Bank Debit Card",
                  "platform_title": "AU Small Finance Bank",
                  "concierge_name": "AU Small Finance Bank",
                  "login_modal_control": "Opt in to receive offers & benefits"
              },
              "footer_additional_content": "<p>Disclaimer: AU Small Finance Bank Offers Platform is a platform for communication of personalized offers extended by Merchants to AU Small Finance Bank's Customers. AU Small Finance Bank is only communicating the offers extended by Merchants to its Customers and not Selling/Rendering any of these Products/Services. AU Small Finance Bank is neither guaranteeing nor making any representation. AU Small Finance Bank is not responsible for Sale/Quality/Features of the Products/Services under the offers</p><p>Site is best viewed in Chrome V23+, Firefox V15+, Safari 6+ and IE11+ at 1024 x 768 pixels resolution, or above.</p>",
              "content": {
                  "bin_error": {
                      "first4_last4": "Dear Customer, the combination of your mobile number and the first 4 and last 4 digits of your Debit Card number did not get validated. Please try again.",
                      "first6": "Dear customer, please re-check the first 6 digits of your card number you have entered"
                  },
                  "add_card_text": "We assure you that there will no transaction on your AU Bank Debit Card by sharing this information. This is only for verification of your AU Bank Account.",
                  "autocard_check_otp_error": "Dear customer, currently we are unable to verify your account details. Please retry after sometime!",
                  "autocard_check_card_error": "Please login using mobile number registered or linked with your AU Bank  Account",
                  "autocard_check_card_go_to_link": "https://www.aubank.in/personal-banking/savings-account/savings-account-value?utm_source=poshvine&utm_medium=banner&utm_campaign=login_error_message"
              },
              "email": {
                  display: 'customercare@aubank.in', val: 'customercare@aubank.in'
              },
              "phone": {
                  display: '1800-1200-1200', val: '180012001200'
              },
              "rtm_enabled" : false
          };
          break;

          this.data = {
            "logo": {
                "url": "https://images.poshvine.com/logos/OneAssist_Logo.png",
                "footerurl": "https://images.poshvine.com/logos/oneAssist-foot.png",
                "desktop_url": "https://images.poshvine.com/logos/OneAssist_Logo.png",
                "modal_url": "https://images.poshvine.com/logos/OneAssist_Logo.png",
                "favicon": "./assets/favicon/oneassist.ico",
                "head_logo_height": "44",
                "coupon_modal_logo": "https://images.poshvine.com/logos/OneAssist_Logo.png"
            },
            "assets": {
                "img": {
                    "banner": [
                        {'img': 'https://images.poshvine.com/b/oneassist-banner.png', 'id': '', 'tagline': 'Make your OneAssist coverage more rewarding with exclusive rewards and offers'},
                        // {'img': "https://s3-ap-south-1.amazonaws.com/walletbuddy-stg/images/attachments/000/561/697/original/LVB_JOY_Hero_Banner_1920_x_600_Pixels-02_%281%29.jpg?1571665786", 'id': '25'}
                    ]
                },
                'client_css': 'oneassist.scss',
                "manifest": "manifest.webmanifest"
            },
            "links": {
                "projectLink": "https://oneassist.poshvine.com/"
            },
            "colors": {
                "header_bg_color": "#fff",
                "coupon_modal_bg_color": "#14549a",
                "offer_title_color": '#466FE5',
                "main_text_color": '#262626',
                "medium_text_color": '#5C5C5C',
                "light_text_color": '#9D9D9D',
                "main_bg_color": '#F4F4F4',
                "active_card_bg_color": '#F4F4F4',
                "seconday_bg_color": '#FDFCFA',
                "mainBtn_color": '#0282f0',
                "inactive_btn_color": '#D8D8D8',
                "btn_positive_action_color": '#06B08A',
                "active_text_color": '#14549a',
            },
            "legal_name" : "OneAssist",
            "font_family": {
              "link": "https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap",
              "name": "Lato"
            },
            "x_header": {
                "prod": {
                    'client_id': '6280c8c7-327b-4c89-be72-9c8c1a89ada7',
                    'project_id_credit': '3388bbd3-7e9b-4575-84f3-a0e53a6c4ace',
                    'project_id_debit': null,
                },
                "sandbox": {
                    'client_id': '37c52c72-4230-4ebb-aa7c-1f92f7cc26b9',
                    'project_id_credit': '05ed3a70-e92f-49d0-92a4-39f99243dc48',
                    'project_id_debit': null,
                },
                "default_card_type": 'debit'
            },
            "search_type": 1,
            "desktop_category_count": 9,
            "card_num_type": null,
            "card_type": "Debit",
            "tabs_type": "category",
            "tabs_type_mobile": "category",
            "has_exclusive": false,
            "banner_type": "static",
            "login_button_needed": true,
            "loginAt": "",
            "login_theme": 1,
            "login_steps": ['mobile','otp','card'],
            "auto_expiry": {
                "time" : "",
                "enabled" : false
            },
            "template" : 1,
            "header_type": "default",
            "show_saving_for_bundle_codes": {},
            "download_app_link": {
                'header': true,
                'footer': true,
                'android_link': 'https://play.google.com/store/apps/details?id=com.oneassist',
                'apple_link': 'https://apps.apple.com/in/app/oneassist-for-iphone/id1074619069'
            },
            "login_modal_type": "desktop-prev-header",
            "supported_devices": ['web','mobile'],
            "benefits":['offers'],
            "banner_style": 1,
            "carouselInterval": -1,
            "api_version": 'v2',
            "personalized_config": {
                "coupon_terms_link": false,
                "switch_card": false,
                "autocard_check": false,
                "view_savings": true,
                "external_otp": true,
                "redemption_page_needed": true,
                "saving_page": false,
                "resend_allowed": true,
                "add_new_card": false,
                "footer_logo": true,
                "force_auth": false,
                "token_auth": false,
                "desktop_search": true,
                "mobile_search": true,
                "auth": true,
                "coupon_modal": true,
                "coupon_show": true,
                "amount_show": false,
                "modal_redeem_button": true,
                "mobile_home_back_button": true,
                "show_card_used": true,
                "show_bundle_used": false,
                "allow_user_create": true,
                "dedicated_banner": false,
                "mobile_banner": false,
                "authenticate_before_claim": true,
                "disclaimer_popup": false,
                "cancelable_auth": true,
                "display_bundle_category_title": true,
                "mobile_login": true,
                "is_concierge": false,
                "has_card": false,
                "home_subcategory_dropdown": false,
                "home_subcategory_bar": false,
                "has_near_by": false,
                "has_international": false,
                "mobile_profile_dropdown": true,
                "category_page": true,
                "authenticate_bin": true,
                "datalayer": false,
                "has_add_card_text": false,
                "custom_gtm_tags": false,
                "show_terms_before_redeem": false,
                "details_desktop_tabs": false,
                "show_special_category": false,
                "send_project_id": false,
                "show_card_applicability": false,
                "card_applicability_fetch": 'api',
                "show_card_select_on_auth": false,
                "special_bundle": false,
                "external_login_banner": false,
                "has_international_offers": false,
                "send_as_gift": false,
                "applicableTag": false,
                "has_maps": false
            },
            "titles": {
                "homepage_pagetitle": "Perks - OneAssist",
                "homepage_description": "",
                "homepage_keywords": "",
                "header_title": "Perks",
                "modal_mobile_label": "Enter your mobile number registered with OneAssist",
                "card_name" : "OneAssist Card",
                "platform_title": "OneAssist Perks",
                "concierge_name": "OneAssist",
                "login_modal_control": "Validate Your Mobile Number"
            },
            "footer_additional_content": "<p>Disclaimer: OneAssist Offers & Privileges is a platform for communication of personalized offers extended by Merchants to OneAssist's Customers. OneAssist is only communicating the offers extended by Merchants to its Customers and not Selling/Rendering any of these Products/Services. OneAssist is neither guaranteeing nor making any representation. OneAssist is not responsible for Sale/Quality/Features of the Products/Services under the offers.</p><p>Site is best viewed in Chrome V23+, Firefox V15+, Safari 6+ and IE11+ at 1024 x 768 pixels resolution, or above.</p>",
            "content":{
                "bin_error": {
                    "first4_last4": "Dear customer, please re-check the first 4 and last 4 card number you have entered",
                    "first6": "Dear customer, please re-check the first 6 digits of your card number you have entered"
                },
                "bin_invalid": {
                    "first4_last4": "Please enter your valid first 4 and last 4 digits of your debit card correctly. In case the issue persists, write to support@poshvine.com",
                    "first6": "Invalid Card"
                }
            },
            "email": {
                display: 'support@poshvine.com', val: 'support@poshvine.com'
            },
            "rtm_enabled" : false
        };
        break;

          this.data = {
              "logo": {
                  "url": "https://images.poshvine.com/logos/kotakwhite-logo.png",
                  "footerurl": "./assets/favicon/kotak.ico",
                  "desktop_url": "https://images.poshvine.com/logos/kotakwhite-logo.png",
                  "modal_url": "https://images.poshvine.com/logos/kotakwhite-logo.png",
                  "favicon": "./assets/favicon/kotak.ico",
                  "head_logo_height": "60",
                  "coupon_modal_logo": "https://images.poshvine.com/logos/kotakwhite-logo.png"
              },
              "assets": {
                  "img": {},
                  "manifest": "manifest.webmanifest"
              },
              "links": {
                  "projectLink": "https://kotak-mahindra-bank.poshvine.com/"
              },
              "colors": {
                  "header_bg_color": "#fff",
                  "coupon_modal_bg_color": "#fff",
                  "offer_title_color": '#003366',
                  "main_text_color": '#262626',
                  "medium_text_color": '#5C5C5C',
                  "light_text_color": '#9D9D9D',
                  "main_bg_color": '#555a5b',
                  "active_card_bg_color": '#F4F4F4',
                  "seconday_bg_color": '#f5f5f5',
                  "mainBtn_color": '#bca36d',
                  "inactive_btn_color": '#D8D8D8',
                  "btn_positive_action_color": '#06B08A',
                  "active_text_color": '#bca36d',
              },
              "legal_name": "Kotak",
              "font_family": {
                  "link": "https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap",
                  "name": "Roboto"
              },
              "x_header": {
                  "prod": {
                      'client_id': '0dabaa48-646f-411b-a4d5-a3d422c61c63',
                      'project_id_credit': 'd5dacbae-f0e4-4594-adea-78ce733a3b42',
                      'project_id_debit': null,
                  },
                  "sandbox": {
                      'client_id': 'b659ae7a-202b-4313-af9d-ef61055973e6',
                      'project_id_credit': '1cc5b87b-82b8-4190-b58e-23e4a1a6a6e2',
                      'project_id_debit': null,
                  },
                  "default_card_type": 'credit'
              },
              "search_type": 2,
              "desktop_category_count": 9,
              "card_num_type": "last4",
              "card_type": "Debit",
              "tabs_type": "category",
              "tabs_type_mobile": "category",
              "has_exclusive": false,
              "banner_type": "none",
              "login_button_needed": true,
              "loginAt": "external",
              "login_theme": 1,
              "login_steps": ['mobile','otp','card'],
              "auto_expiry": {
                  "time": "",
                  "enabled": false
              },
              "template": 3,
              "header_type": "default",
              "show_saving_for_bundle_codes": {},
              "download_app_link": {
                  'header': false,
                  'footer': false,
                  'android_link': null,
                  'apple_link': null
              },
              "login_modal_type": "desktop-prev-header",
              "supported_devices": ['mobile'],
              "benefits": ['giftcards'],
              "banner_style": 1,
              "carouselInterval": -1,
              "api_version": 'v2',
              "credits_type": 'user',
              "personalized_config": {
                  "coupon_terms_link": false,
                  "switch_card": false,
                  "autocard_check": false,
                  "view_savings": true,
                  "external_otp": false,
                  "redemption_page_needed": true,
                  "redemption_terms_btn": false,
                  "saving_page": false,
                  "resend_allowed": true,
                  "add_new_card": false,
                  "footer_logo": true,
                  "force_auth": true,
                  "token_auth": false,
                  "desktop_search": true,
                  "mobile_search": true,
                  "auth": true,
                  "coupon_modal": true,
                  "coupon_show": true,
                  "amount_show": false,
                  "modal_redeem_button": true,
                  "mobile_home_back_button": true,
                  "show_card_used": false,
                  "show_bundle_used": false,
                  "allow_user_create": false,
                  "dedicated_banner": false,
                  "mobile_banner": false,
                  "authenticate_before_claim": true,
                  "display_bundle_category_title": true,
                  "disclaimer_popup": false,
                  "cancelable_auth": false,
                  "mobile_login": false,
                  "is_concierge": false,
                  "has_card": true,
                  "home_subcategory_dropdown": false,
                  "home_subcategory_bar": false,
                  "has_near_by": false,
                  "has_international": false,
                  "mobile_profile_dropdown": false,
                  "category_page": true,
                  "authenticate_bin": true,
                  "datalayer": false,
                  "has_add_card_text": false,
                  "single_step_login": false,
                  "skip_card_if_exist": false,
                  "show_card_select_on_auth": false,
                  "special_bundle": false,
                  "external_login_banner": false,
                  "send_project_id": true,
                  "send_as_gift": true,
                  "applicableTag": false,
                  "has_maps": false
              },
              "titles": {
                  "homepage_pagetitle": "Giftcards | Kotak Mahindra Bank",
                  "homepage_description": "",
                  "homepage_keywords": "",
                  "header_title": "",
                  "modal_mobile_label": "Enter you mobile number and Card no registered with Kotak Mahindra bank for OTP authentication",
                  "card_name": "Kotak Mahindra Bank White Credit Card",
                  "platform_title": "Kotak Mahindra Bank Offers and Benefits",
                  "concierge_name": "Kotak Mahindra Bank",
                  "login_modal_control": "Validate Your Mobile Number",
                  "card_name_extra_text": ""
              },
              "footer_additional_content": "<p>Disclaimer: Kotak Mahindra Bank Offers & Privileges is a platform for communication of personalized offers extended by Merchants to Kotak Mahindra Bank's Customers. Kotak Mahindra Bank is only communicating the offers extended by Merchants to its Customers and not Selling/Rendering any of these Products/Services. Kotak Mahindra Bank is neither guaranteeing nor making any representation. Kotak Mahindra Bank is not responsible for Sale/Quality/Features of the Products/Services under the offers.</p><p>Site is best viewed in Chrome V23+, Firefox V15+, Safari 6+ and IE11+ at 1024 x 768 pixels resolution, or above.</p>",
              "content": {
                  "bin_error": {
                      "first4_last4": "Dear customer, please re-check the first 4 and last 4 card number you have entered",
                      "first6": "Dear customer, please re-check the first 6 digits of your card number you have entered",
                      "last4": "Dear customer, please re-check the last 4 digits of the card number you have entered"
                  },
                  "bin_invalid": {
                      "first4_last4": "Please enter your valid first 4 and last 4 digits of your debit card correctly. In case the issue persists, write to support@poshvine.com",
                      "first6": "Invalid Card",
                      "last4": "Dear customer, please re-check the last 4 digits of your card number you have entered"
                  },
                  "preset_error": "Dear Customer, please check if you have entered your bank-registered mobile number."
              },
              "rtm_enabled" : false
          };
          break;

          this.data = {
              "logo": {
                  "url": "https://images.poshvine.com/logos/citi-new.png",
                  "footerurl": "https://images.poshvine.com/logos/citi-small.png",
                  "desktop_url": "https://images.poshvine.com/logos/citi-new.png",
                  "modal_url": "https://images.poshvine.com/logos/citi-new.png",
                  "favicon": "./assets/favicon/citi.ico",
                  "head_logo_height": "42",
                  "coupon_modal_logo": "https://images.poshvine.com/logos/citi-new.png"
              },
              "assets": {
                  "img": {
                    "banner": [
                        {'img': 'https://s3.ap-south-1.amazonaws.com/images.poshvine.com/b/citi-main-b.png', 'id': '','tagline': '', 'subTagline': ""},
                        ]
                  },
                  'client_css': 'citi.scss',
                  "manifest": "manifest.webmanifest"
              },
              "links": {
                  "projectLink": "https://citi.poshvine.com/"
              },
              "colors": {
                  "header_bg_color": "#055597",
                  "coupon_modal_bg_color": "#195993",
                  "offer_title_color": '#195993',
                  "main_text_color": '#262626',
                  "medium_text_color": '#5C5C5C',
                  "light_text_color": '#9D9D9D',
                  "main_bg_color": '#F4F4F4',
                  "active_card_bg_color": '#F4F4F4',
                  "seconday_bg_color": '#FDFCFA',
                  "mainBtn_color": '#ed8320',
                  "inactive_btn_color": '#D8D8D8',
                  "btn_positive_action_color": '#195993',
                  "active_text_color": '#195993',
              },
              "legal_name" : "Citi Lifestyle Benefits",
              "font_family": {
                "link": "https://images.poshvine.com/fonts/interstate/Interstate.css",
                "name": "Interstate"
              },
              "x_header": {
                      "prod": {
                          'client_id': '3388bbd3-7e9b-4575-84f3-a0e53a6c4ace',
                          'project_id_credit': '3388bbd3-7e9b-4575-84f3-a0e53a6c4ace',
                          'project_id_debit': null,
                      },
                      "sandbox": {
                          'client_id': 'da7463a3-1c26-4611-8e04-f5e766d39348',
                          'project_id_credit': 'e6954e02-a36b-415e-b007-6c0d21825761',
                          'project_id_debit': null,
                      },
                      "default_card_type": 'credit'
              },
              "search_type": 1,
              "desktop_category_count": 9,
              "card_num_type": 'first6',
              "card_type": "Debit/Credit",
              "tabs_type": "subcategory",
              "tabs_type_mobile": "subcategory",
              "has_exclusive": false,
              "banner_type": "static",
              "login_button_needed": true,
              "loginAt": "otp",
              "login_theme": 1,
              "login_steps": ['card','mobile','otp'],
              "auto_expiry": {
                  "time" : "",
                  "enabled" : false
              },
              "template" : 1,
              "header_type": "default",
              "show_saving_for_bundle_codes": {},
              "download_app_link": {
                  'header': false,
                  'footer': false,
                  'android_link': '',
                  'apple_link': ''
              },
              "login_modal_type": "desktop-prev-header",
              "supported_devices": ['web','mobile'],
              "benefits":['offers'],
              "banner_style": 1,
              "carouselInterval": -1,
              "api_version": 'v2',
              "personalized_config": {
                  "coupon_terms_link": false,
                  "switch_card": false,
                  "autocard_check": false,
                  "view_savings": true,
                  "external_otp": false,
                  "redemption_page_needed": true,
                  "saving_page": false,
                  "resend_allowed": true,
                  "add_new_card": true,
                  "footer_logo": false,
                  "force_auth": false,
                  "token_auth": false,
                  "desktop_search": true,
                  "mobile_search": true,
                  "auth": true,
                  "coupon_modal": true,
                  "coupon_show": true,
                  "amount_show": false,
                  "modal_redeem_button": true,
                  "mobile_home_back_button": true,
                  "show_card_used": false,
                  "show_bundle_used": false,
                  "allow_user_create": true,
                  "dedicated_banner": false,
                  "mobile_banner": true,
                  "authenticate_before_claim": true,
                  "disclaimer_popup": false,
                  "cancelable_auth": true,
                  "display_bundle_category_title": true,
                  "mobile_login": true,
                  "is_concierge": false,
                  "has_card": true,
                  "home_subcategory_dropdown": false,
                  "home_subcategory_bar": false,
                  "has_near_by": false,
                  "has_international": false,
                  "mobile_profile_dropdown": true,
                  "category_page": true,
                  "authenticate_bin": true,
                  "datalayer": false,
                  "custom_gtm_tags": false,
                  "show_terms_before_redeem": false,
                  "details_desktop_tabs": false,
                  "show_special_category": false,
                  "send_project_id": true,
                  "show_card_applicability": false,
                  "card_applicability_fetch": 'api',
                  "show_card_select_on_auth": false,
                  "special_bundle": false,
                  "external_login_banner": false,
                  "has_international_offers": false,
                  "redemption_page_display_card_number": true,
                  "has_add_card_text": false,
                  "send_as_gift": false,
                  "applicableTag": false,
                  "has_maps": false
              },
              "titles": {
                  "homepage_pagetitle": "Citi Lifestyle Benefits",
                  "homepage_description": "",
                  "homepage_keywords": "",
                  "header_title": "",
                  "modal_mobile_label": "Enter your mobile number registered with Citibank",
                  "card_name" : "Citibank card",
                  "platform_title": "Citi Lifestyle Benefits",
                  "concierge_name": "Citi Lifestyle Benefits",
                  "login_modal_control": "Validate Your Mobile Number"
              },
              "footer_additional_content": "<p>Disclaimer: This platform is curated for communication of personalized offers extended by Merchants to Citibank's Customers. This platform is only communicating the offers extended by Merchants to its Customers and not Selling/Rendering any of these Products/Services. The platform neither guarantees nor makes any representation. This platfrom is not responsible for Sale/Quality/Features of the Products/Services under the offers.</p><p>Site is best viewed in Chrome V23+, Firefox V15+, Safari 6+ and IE11+ at 1024 x 768 pixels resolution, or above.</p>",
              "content":{
                  "bin_error": {
                      "first4_last4": "Dear customer, please re-check the first 4 and last 4 digits of the card number you have entered",
                      "first6": "Dear customer, please re-check the first 6 digits of your card number you have entered"
                  },
                  "bin_invalid": {
                      "first4_last4": "Please enter your valid first 4 and last 4 digits of your debit card correctly. In case the issue persists, write to support@poshvine.com",
                      "first6": "Invalid Card"
                  }
              },
              "email": {
                  display: 'support@poshvine.com', val: 'support@poshvine.com'
              },
              "rtm_enabled" : false
          };
          break;

          this.data = {
              "logo": {
                  "url": "https://images.poshvine.com/logos/RuPay-0.png",
                  "footerurl": "https://images.poshvine.com/logos/RuPay-0.png",
                  "desktop_url": "https://images.poshvine.com/logos/RuPay-0.png",
                  "modal_url": "https://images.poshvine.com/logos/RuPay-01.png",
                  "favicon": "./assets/favicon/rupay.ico",
                  "head_logo_height": "26",
                  "coupon_modal_logo": "https://images.poshvine.com/logos/RuPay-0.png"
              },
              "assets": {
                  "img": {
                      "banner": [
                          {'img': 'https://images.poshvine.com/b/ru-b.jpg', 'id': '','tagline': ''},
                          // {'img': "https://s3-ap-south-1.amazonaws.com/walletbuddy-stg/images/attachments/000/561/697/original/LVB_JOY_Hero_Banner_1920_x_600_Pixels-02_%281%29.jpg?1571665786", 'id': '25'}
                      ],
                  },
                  "manifest": "manifest.webmanifest"

              },
              "links": {
                  "projectLink": "https://rupay-smebenefits.poshvine.com/"
              },
              "colors": {
                  "header_bg_color": "#243A7E",
                  "coupon_modal_bg_color": "#28357e",
                  "offer_title_color": '#d9782d',
                  "main_text_color": '#262626',
                  "medium_text_color": '#5C5C5C',
                  "light_text_color": '#9D9D9D',
                  "main_bg_color": '#F4F4F4',
                  "active_card_bg_color": '#F4F4F4',
                  "seconday_bg_color": '#FDFCFA',
                  "mainBtn_color": '#28357e',
                  "inactive_btn_color": '#D8D8D8',
                  "btn_positive_action_color": '#06B08A',
                  "active_text_color": '#d9782d',
              },
              "legal_name" : "RuPay",
              "font_family": {
                "link": "https://images.poshvine.com/fonts/gotham-round/Gotham-Rounded.css",
                "name": "Gotham-Rounded"
              },
              "x_header": {
                  "prod": {
                      'client_id': '78f76a6f-376f-4c49-bca1-ba57c3c6d443',
                      'project_id_credit': '73ff0d78-d8c3-4294-a008-bec0dcf5ea23',
                      'project_id_debit': '73ff0d78-d8c3-4294-a008-bec0dcf5ea23',
                  },
                  "sandbox": {
                      'client_id': '19198a1b-1839-4e22-8063-28680741c471',
                      'project_id_credit': '6db95148-55ff-48ba-819a-d6808930706a',
                      'project_id_debit': null,
                  },
                  "default_card_type": 'credit'
              },
              "card_num_type": "first6",
              "card_type": "Debit",
              "tabs_type": "category",
              "tabs_type_mobile": "category",
              "enable_all_cat": true,
              "has_exclusive": false,
              "banner_type": "static",
              "login_button_needed": true,
              "loginAt": "otp",
              "login_theme": 1,
              "login_steps": ['card','mobile','otp'],

              "auto_expiry": {
                  "time" : "",
                  "enabled" : false
              },
              "template" : 1,
              "header_type": "default",
              "show_saving_for_bundle_codes": {},
              "download_app_link": {
                  'header': false,
                  'footer': false,
                  'android_link': null,
                  'apple_link': null
              },
              "login_modal_type": "desktop-prev-header",
              "supported_devices": ['web','mobile'],
              "benefits":['offers'],
              "banner_style": 1,
              "carouselInterval": -1,
              "api_version": 'v2',
              "personalized_config": {
                  "coupon_terms_link": false,
                  "switch_card": false,
                  "autocard_check": false,
                  "view_savings": true,
                  "external_otp": false,
                  "redemption_page_needed": true,
                  "saving_page": false,
                  "resend_allowed": true,
                  "add_new_card": true,
                  "footer_logo": false,
                  "force_auth": false,
                  "token_auth": false,
                  "desktop_search": false,
                  "mobile_search": true,
                  "auth": true,
                  "coupon_modal": true,
                  "coupon_show": true,
                  "amount_show": false,
                  "modal_redeem_button": true,
                  "mobile_home_back_button": true,
                  "show_card_used": false,
                  "show_bundle_used": false,
                  "allow_user_create": true,
                  "dedicated_banner": true,
                  "mobile_banner": true,
                  "authenticate_before_claim": true,
                  "disclaimer_popup": true,
                  "cancelable_auth": true,
                  "display_bundle_category_title": true,
                  "mobile_login": true,
                  "is_concierge": false,
                  "has_card": true,
                  "home_subcategory_dropdown": true,
                  "home_subcategory_bar": false,
                  "has_near_by": false,
                  "mobile_profile_dropdown": true,
                  "category_page": true,
                  "authenticate_bin": true,
                  "datalayer": false,
                  "has_add_card_text": false,
                  "redemption_page_display_card_number": true,
                  "send_project_id": true,
                  "show_card_select_on_auth": false,
                  "applicableTag": false,
                  "has_maps": false
              },
              "titles": {
                  "homepage_pagetitle": "Offers & Privileges - RuPay",
                  "homepage_description": "",
                  "homepage_keywords": "",
                  "header_title": "",
                  "modal_mobile_label": "Enter your mobile number registered with RuPay card",
                  "card_name" : "RuPay card",
                  "platform_title": "RuPay",
                  "concierge_name": "RuPay",
                  "login_modal_control": "Opt in to receive offers & benefits"
              },
              "footer_additional_content": "<p>Disclaimer: RuPay SME Benefits is a platform for communication of offers extended by Merchants to RuPay's Corporate Cardholders. RuPay is only communicating the offers extended by Merchants to its Cardholders and not Selling/Rendering any of these Products/Services. RuPay is neither guaranteeing nor making any representation. RuPay is not responsible for Sale/Quality/Features of the Products/Services under the offers listed here.</p>",
              "content":{
                  "bin_error": {
                      "first4_last4": "Dear Customer, the combination of your mobile number and the first 4 and last 4 digits of your Debit Card number did not get validated. Please try again.",
                      "first6": "Dear customer, please re-check the first 6 digits of your card number you have entered"
                  },
                  "bin_invalid": {
                      "first4_last4": "Please enter your valid first 4 and last 4 digits of your debit card correctly. In case the issue persists, write to support@poshvine.com",
                      "first6": "Invalid Card"
                  }
              },
              "rtm_enabled" : false
          };
          break;
      default:

        this.data = {
          "x_header": {
              "prod": {
                'project_id': '32b8e36e-0b6e-4263-8358-c0818ec4bf22'
              },
              "sandbox": {
                  'project_id': 'e83c513f-e29a-46d3-b00d-3ef45f18f163'
              },
          },
          "landing_page_banner": 'https://images.poshvine.com/collinson/ppai.png',
          "discount_label": 'Discount',
          "subtitle_text": "Use your eligible card to avail these services at discounted rates",
          "footer_text": 'Priority Pass Access India™ is a platform for communication of personalized offers extended by merchants to their Customers. Priority Pass Access India is only communicating the offers extended by merchants to its customers and not selling/rendering any of these products/services. Priority Pass Access India™ is neither guaranteeing nor making any representation.',
          "prelogin_text": 'Add your card',
          "link": 'https://prioritypassaccessindia.com'

        };
        break;
  }
  }
}
